import React, { createContext, useState } from "react";
import { Form, Button, Space, Modal, notification, Spin, Row, Col, message, Upload } from 'antd';
import '../index.scss'
import { ArrowLeftOutlined, SaveOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from "axios";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import Icon, { RightOutlined } from '@ant-design/icons';

import { BASE_URL } from 'services/config';
import Dragger from "antd/es/upload/Dragger";
import { DeleteIcon, DownloadAbleIcon, UploadIcon } from "components/atoms/SVG";
import TextArea from "antd/es/input/TextArea";
import { useEffect } from "react";
import { downloadFile } from "utils";

import { ReactComponent as Confirmation } from "../../../assets/logo/Confirmation.svg"
import moment from "moment";

const ReachableContext = createContext(null);

const HomeLine = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const DetailKontrak = () => {
  const params = useParams();
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

  const [loading, setLoading] = useState(false);

  const [draftDocuments, setDraftDocuments] = useState();
  const [additionalDocuments, setAdditionalDocuments] = useState();
  const [finalDocuments, setFinalDocuments] = useState();
  const [notes, setNotes] = useState();
  const [dataKontrak, setDataKontrak] = useState();

  const queryParams = new URLSearchParams(window.location.search);
  const role = queryParams.get('role');
  const group = queryParams.get('group');

  useEffect(() => {
    loadDetailDataKontrak()
  }, [])

  const loadDetailDataKontrak = () => {
    axios.get(`${BASE_URL}/recap/${params.id}/contract`)
      .then(res => {
        let kontrak = res.data.result;
        setDataKontrak(kontrak);

        setDraftDocuments(kontrak?.contract?.documents?.draft);
        setAdditionalDocuments(kontrak?.contract?.documents?.additional);
        setFinalDocuments(kontrak?.contract?.documents?.final);
        setNotes(kontrak?.contract?.notes)
      })
      .catch((err) => {
        notification.error({
          message: "Kesalahan",
          description: err.message
        });
      })
  };

  const normalizePayload = () => {
    let payload = {
      "documents": {
        "draft": draftDocuments,
        "additional": additionalDocuments
      },
      "notes": notes
    }

    return payload;
  }

  const onFinish = (values) => {
    const payload = normalizePayload(values);

    if (payload.documents.draft) {
      Modal.confirm({
        title: '',
        icon: <Confirmation />,
        okText: "Confirm",
        bodyStyle: { justifyContent: "center" },
        content: (
          <>
            <h1
              className=""
              style={{ fontSize: "17px", fontWeight: "600", lineHeight: "24px" }}
            >
              Apakah Anda yakin akan menyimpan data ini?
            </h1>
            <br />
          </>
        ),
        onOk() {
          setLoading(true);
          axios.put(`${BASE_URL}/contract/${params.id}`, payload)
            .catch((err) => {
              let errorMessage;
              let errorMessages = [];

              if (err.response.data.errors === undefined || err.response.data.errors.length === 0) {
                errorMessage = err.response.data.message;
              } else {
                <ul style={{ listStyleType: "disc" }}>
                  {
                    err.response.data.errors.forEach((error) => {
                      errorMessages.push(<li>&#x2022; {error.message}</li>);
                    })
                  }
                </ul>
              }
              notification.error({
                message: 'Kesalahan',
                duration: 5,
                description: errorMessage ? errorMessage : errorMessages
              });
              setLoading(false);
            })
            .then((res) => {
              setLoading(false);
              if (res) {
                onDone();
              }
            });
        }
      });
    } else {
      notification.error({
        message: "Kesalahan",
        description: "Dokumen Draft Awal Perjanjian dari Donor Belum di Upload"
      });
    }
  };

  const onDone = () => {
    Modal.success({
      title: '',
      okText: "Confirm",
      content: (
        <>
          <h1
            className=""
            style={{ fontSize: "17px", fontWeight: "600", lineHeight: "24px", textTransform: "capitalize" }}
          >
            Data berhasil disimpan!
          </h1>
          <br />
        </>
      ),
      onOk() {
        return navigate("/perjanjian");
      }
    })
  };

  const handleDeleteDocument = (docType) => {
    Modal.confirm({
      centered: true,
      title: '',
      icon: <Confirmation />,
      okText: "Confirm",
      bodyStyle: { justifyContent: "center" },
      content: (
        <>
          <h1 className="mb-4" style={{ fontSize: "17px", fontWeight: "600", lineHeight: "24px", justifyContent: "center" }}>
            Anda yakin menghapus dokumen ini?
          </h1>
        </>
      ),
      onOk() {
        if (docType === "draftDocuments") {
          setDraftDocuments(null);
        } else {
          setAdditionalDocuments(null);
        }
      }
    })
  }

  const propsDraft = {
    name: 'file',
    // multiple: true,
    action: 'https://bpldh-api.groot.id/api/file-service/v1/files',
    beforeUpload: (file) => {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        // message.error(`${file.name} is not a pdf file`);
        notification.error({
          message: "Kesalahan",
          description: `Dokumen yang dapat di-upload adalah Dokumen PDF`
        });
      }

      const isSizeAllowed = file.size <= 10 * 1024 * 1024;
      if (!isSizeAllowed) {
        notification.error({
          message: "Kesalahan",
          description: `Ukuran Dokumen yang di Upload Melebihi 10MB`
        });
      }

      return (isPDF && isSizeAllowed) || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        let doc = info.file.response?.result
        let _draftDocuments = {
          "fileId": doc.id,
          "fileName": doc.name,
          "fileSize": doc.size,
          "mimeType": doc.mimeType,
          "path": doc.key
        }
        setDraftDocuments(_draftDocuments)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const propsAdditional = {
    name: 'file',
    // multiple: true,
    action: 'https://bpldh-api.groot.id/api/file-service/v1/files',
    beforeUpload: (file) => {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        // message.error(`${file.name} is not a pdf file`);
        notification.error({
          message: "Kesalahan",
          description: `Dokumen yang dapat di-upload adalah Dokumen PDF`
        });
      }

      const isSizeAllowed = file.size <= 10 * 1024 * 1024;
      if (!isSizeAllowed) {
        notification.error({
          message: "Kesalahan",
          description: `Ukuran Dokumen yang di Upload Melebihi 10MB`
        });
      }

      return (isPDF && isSizeAllowed) || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        let doc = info.file.response?.result
        let _additionalDocuments = {
          "fileId": doc.id,
          "fileName": doc.name,
          "fileSize": doc.size,
          "mimeType": doc.mimeType,
          "path": doc.key
        }
        setAdditionalDocuments(_additionalDocuments)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <>
      <Spin spinning={loading} indicator={spinIcon}>
        <div>

          <div className="w-100 list overflow-hidden mb-6" style={{ borderRadius: "8px", border: "1px solid #EAECF0" }}>
            <div className="">
              <div style={{ background: "#00BD52", height: "10px" }}></div>
              <div className="px-5 py-5">
                <p className="text-2xl font-medium">Informasi Perjanjian</p>
              </div>
              <hr />
              <div className="px-5 mt-4">
                <Row>
                  <Col span={8}>
                    <div className="text-sub-body">ID Proposal</div>
                    <div className="text-body">{dataKontrak?.proposalNumber + "." + dataKontrak?.subProposalNumber || '-'}</div>
                  </Col>

                  <Col span={8}>
                    <div className="text-sub-body">Judul Proposal</div>
                    <div className="text-body">{dataKontrak?.generalInformation?.title || '-'}</div>
                  </Col>

                  <Col span={8}>
                    <div className="text-sub-body">Nama Donor</div>
                    <div className="text-body">{dataKontrak?.donor?.donor || '-'}</div>
                  </Col>
                </Row>

                <Row>
                  <Col span={8}>
                    <div className="text-sub-body">Nomor Perjanjian</div>
                    <div className="text-body">{dataKontrak?.contract?.number || '-'}</div>
                  </Col>

                  <Col span={8}>
                    <div className="text-sub-body">Nama Project</div>
                    <div className="text-body">{dataKontrak?.contract?.projectName || '-'}</div>
                  </Col>

                  <Col span={8}>
                    <div className="text-sub-body">Tanggal Penetapan</div>
                    <div className="text-body">
                      {dataKontrak?.contract?.confirmationDate ? moment(dataKontrak?.contract?.confirmationDate).format("DD/MM/YYYY") : '-'}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <div className="text-sub-body">Windows</div>
                    <div className="text-body">
                      <div className="text-body">{dataKontrak?.contract?.windows || '-'}</div>
                      {/* {dataKontrak?.contract?.windows ?  moment(dataKontrak?.contract?.windows).format("DD/MM/YYYY") : '-'} */}
                    </div>
                  </Col>
                </Row>
              </div>
              <hr className="mb-4" />
            </div>
          </div>

          <div className="w-100 list overflow-hidden mb-6" style={{ borderRadius: "8px", border: "1px solid #EAECF0" }}>
            <div className="">
              <div style={{ background: "#00BD52", height: "10px" }}></div>
              <div className="px-5 py-5">
                <p className="text-2xl font-medium">Draft Awal Perjanjian dari Donor</p>
              </div>
              <hr />
              <div className="px-5 mt-4 mb-4">
                <Row justify={"center"}>
                  <Col span={24}>
                    {draftDocuments ?
                      <div className="flex justify-between mr-4" style={{ border: "1px solid #EAECF0", borderRadius: "12px", padding: "16px", width: "335px", height: "96px" }}>
                        <div className="mr-4">
                          <Icon component={DownloadAbleIcon} />
                        </div>
                        <div className="row mr-10">
                          <p className="wrap-text bold">{draftDocuments.fileName} </p> <span>({draftDocuments.fileSize}KB)</span>
                          {draftDocuments.url &&
                            <div className="flex justify-between">
                              <strong
                                style={{ color: "#00BD52", cursor: "pointer" }}
                                onClick={() => { window.open(draftDocuments.url, '_blank') }}
                              >
                                Buka
                              </strong>
                              <strong
                                style={{ color: "#00BD52", cursor: "pointer" }}
                                onClick={() => { downloadFile(draftDocuments.url, draftDocuments.fileName) }}
                              >
                                Unduh
                              </strong>
                            </div>
                          }
                        </div>
                        <div className="ml-4"
                        //  style={{ cursor: "pointer" }}
                        //  onClick={() => { handleDeleteDocument("draftDocuments") }}
                        >
                          <Icon component={DeleteIcon} />
                        </div>
                      </div>
                      :
                      <>
                        -
                      </>
                    }
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <div className="w-100 list overflow-hidden mb-6" style={{ borderRadius: "8px", border: "1px solid #EAECF0" }}>
            <div className="">
              <div style={{ background: "#00BD52", height: "10px" }}></div>
              <div className="px-5 py-5">
                <p className="text-2xl font-medium">Dokumen Final Perjanjian Donor</p>
              </div>
              <hr />
              <div className="px-5 mt-4 mb-4">
                <Row justify={"center"}>
                  <Col span={24}>
                    {finalDocuments ?
                      <div className="flex justify-start mr-4" style={{ border: "1px solid #EAECF0", borderRadius: "12px", padding: "16px", width: "335px", height: "96px" }}>
                        <div className="mr-4">
                          <Icon component={DownloadAbleIcon} />
                        </div>
                        <div className="row mr-10">
                          <p className="wrap-text bold">{finalDocuments.fileName} </p> <span>({finalDocuments.fileSize}KB)</span>
                          {finalDocuments.url &&
                            <div className="flex justify-between">
                              <strong
                                style={{ color: "#00BD52", cursor: "pointer" }}
                                onClick={() => { window.open(finalDocuments.url, '_blank') }}
                              >
                                Buka
                              </strong>
                              <strong
                                style={{ color: "#00BD52", cursor: "pointer" }}
                                onClick={() => { downloadFile(finalDocuments.url, finalDocuments.fileName) }}
                              >
                                Unduh
                              </strong>
                            </div>
                          }
                        </div>
                      </div>
                      :
                      <>
                        Dokumen Final Perjanjian Donor akan diproses oleh tim Perjanjian.
                      </>
                    }
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <div className="w-100 list overflow-hidden mb-6" style={{ borderRadius: "8px", border: "1px solid #EAECF0" }}>
            <div className="">
              <div style={{ background: "#00BD52", height: "10px" }}></div>
              <div className="px-5 py-5">
                <p className="text-2xl font-medium">Dokumen Pendukung</p>
              </div>
              <hr />
              <div className="px-5 mt-4 mb-4">
                <Row justify={"center"}>
                  <Col span={24}>
                    {additionalDocuments ?
                      <Row>
                        {additionalDocuments.map(additionalDocument => (
                          <div className="flex justify-between mr-4" style={{ border: "1px solid #EAECF0", borderRadius: "12px", padding: "16px", width: "335px", height: "96px" }}>
                            <div className="mr-4">
                              <Icon component={DownloadAbleIcon} />
                            </div>
                            <div className="row mr-10">
                              <p className="wrap-text bold">{additionalDocument.fileName} </p> <span>({additionalDocument.fileSize}KB)</span>
                              {additionalDocument.url &&
                                <div className="flex justify-between">
                                  <strong
                                    style={{ color: "#00BD52", cursor: "pointer" }}
                                    onClick={() => { window.open(additionalDocument.url, '_blank') }}
                                  >
                                    Buka
                                  </strong>
                                  <strong
                                    style={{ color: "#00BD52", cursor: "pointer" }}
                                    onClick={() => { downloadFile(additionalDocument.url, additionalDocument.fileName) }}
                                  >
                                    Unduh
                                  </strong>
                                </div>
                              }
                            </div>
                            <div className="ml-4"
                              style={{ cursor: "pointer" }}
                              onClick={() => { handleDeleteDocument("additionalDocuments") }}
                            >
                              <Icon component={DeleteIcon} />
                            </div>
                          </div>
                        ))}
                      </Row>
                      :
                      <>
                        -
                      </>
                    }
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          {dataKontrak?.contract?.status !== "done" &&
            <div className="w-100 list overflow-hidden mb-6" style={{ borderRadius: "8px", border: "1px solid #EAECF0" }}>
              <div className="">
                <div style={{ background: "#00BD52", height: "10px" }}></div>
                <div className="px-5 py-5">
                  <p className="text-2xl font-medium">Notes/Change Log</p>
                </div>
                <hr />
                <div className="px-5 mt-4 mb-4">
                  <Row justify={"center"}>
                    <Col span={24}>
                      <TextArea
                        value={notes}
                        onChange={(e) => { setNotes(e.target.value) }}
                        rows={4}
                        disabled
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          }

          <div className="mb-16"></div>

          <div className="list mt-6 px-4" style={{ borderRadius: "8px", border: "1px solid #EAECF0", position: "fixed", bottom: 0, width: "calc(100vw - 400px)" }}>
            <div className="flex justify-between items-center">
              <Space>
                <NavLink to={'/rekap'}>
                  <Button
                    style={{ borderRadius: "8px", border: "1px solid #EAECF0", width: "120px", height: "50px" }}
                    icon={<ArrowLeftOutlined />}
                    className="mt-4 mb-4 flex justify-center items-center font-medium"
                  >
                    <span className="ml-2 text-center">Kembali</span>
                  </Button>
                </NavLink>
              </Space>
            </div>
          </div>
        </div >
      </Spin >
    </>
  );
}

export default DetailKontrak;