import React, { useState, useEffect } from "react";
import { Button, Col, Collapse, Input, InputNumber, Modal, Row, Select } from "antd";
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { ReactComponent as Confirmation } from "../../../assets/logo/Confirmation.svg"
import { currencyFormatter, currencyParser } from "utils";

export const OutputForm = ({ Form, form, disable, name, priorityProgramIndex, outcomeIndex, expandIcon, handlePanelChange }) => {
   const { Panel } = Collapse;

   const requiredField = [{ required: true, message: 'Wajib Diisi' }];
   const [listLinkedSubOutput, setListLinkedSubOutput] = useState([]);

   useEffect(() => {
      let arr = [];
      let proposal = form.getFieldsValue(true);

      proposal.priorityPrograms?.forEach(priorityProgram => {
         priorityProgram?.impact?.outcomes?.forEach(outcome => {
            outcome.outputs?.forEach(output => {
               output.subOutputs?.forEach(subOutput => {
                  arr.push({
                     ...subOutput,
                     key: subOutput.originalId,
                     value: subOutput.subOutputName,
                     label: subOutput.subOutputName
                  })
               })
            })
         })
      })

      setListLinkedSubOutput(arr);
   }, [form.getFieldsValue(true)])

   const handleDeleteOutput = (remove, idx) => {
      Modal.confirm({
         centered: true,
         title: '',
         icon: <Confirmation />,
         okText: "Confirm",
         bodyStyle: { justifyContent: "center" },
         content: (
            <>
               <h1 className="mb-4" style={{ fontSize: "17px", fontWeight: "600", lineHeight: "24px", justifyContent: "center" }}>
                  Anda yakin menghapus Output ini?
               </h1>
            </>
         ),
         onOk() {
            remove(idx)
         }
      })
   }

   return (
      <Form.List name={[outcomeIndex, "outputs"]}>
         {(outputs, { add, remove }) => (
            <>
               {outputs.map((output, outputIndex) => {
                  return (
                     <div className="mt-4">
                        <Collapse
                           expandIcon={expandIcon}
                           defaultActiveKey={['1']}
                           expandIconPosition="end"
                           onChange={handlePanelChange}
                           className="mt-2 mb-2"
                        >
                           <Panel className="custom-panel pb-4" style={{ color: "black !important" }} header={`Output ${outputIndex + 1}`} key="1"
                           // extra={(priorityProgramIndex > 0 && !disable) && deletePanel(remove, priorityProgram)}
                           >
                              <Row gutter={12}>
                                 <Col span={24}>
                                    <div className="">
                                       <label className="field-required font-medium">Output</label>&nbsp;
                                       {(outputIndex > 0) && !disable &&
                                          <span><DeleteOutlined onClick={() => { handleDeleteOutput(remove, outputIndex) }} /></span>
                                       }
                                       <Form.Item
                                          name={[outputIndex, 'projectOutput']}
                                          rules={requiredField}
                                       >
                                          <Input
                                             placeholder="Output"
                                             type="text"
                                             className="form-input mt-2"
                                             disabled={disable}
                                          />
                                       </Form.Item>

                                       <label className="field-required font-medium">Linkage Sub Output BPDLH</label>
                                       <Form.Item
                                          name={[outputIndex, 'linkageSubOutput', "subOutputName"]}
                                          rules={requiredField}
                                       >
                                          <Select
                                             placeholder="Sub Output"
                                             type="text"
                                             className="mt-2"
                                             disabled={disable}
                                             options={listLinkedSubOutput}
                                             onChange={(value, option) => {
                                                let linkageSubOutput = {
                                                   originalId: option.key,
                                                   subOutputName: value
                                                }

                                                form.setFieldValue([name, priorityProgramIndex, "outcomes", outcomeIndex, "outputs", outputIndex, "linkageSubOutput"], linkageSubOutput)
                                             }}
                                          />
                                       </Form.Item>

                                       <label className="font-medium">Alokasi Kebutuhan Dana (Rp)</label>
                                       <Form.Item
                                          name={[outputIndex, 'budgetAllocation']}
                                       >
                                          <InputNumber
                                             placeholder="Jumlah Dana"
                                             className="form-input mt-2 w-full"
                                             style={{ height: "47px", width: "50%", textAlign: "left" }}
                                             formatter={currencyFormatter}
                                             parser={currencyParser}
                                             controls={false}
                                             defaultValue={0}
                                             onChange={(val) => {
                                                let obj = form.getFieldsValue([name]);
                                                let priorityPrograms = obj[name];

                                                let alocation = 0;

                                                let priorityProgram = priorityPrograms[priorityProgramIndex]
                                                if (priorityProgram) {
                                                   let outcomes = priorityPrograms[priorityProgramIndex].outcomes;
                                                   if (outcomes) {
                                                      outcomes.forEach((outcome, ocIndex) => {
                                                         let outputs = outcome.outputs;
                                                         if (outputs) {
                                                            outputs.forEach((output, opIndex) => {
                                                               if (output.budgetAllocation) {
                                                                  alocation += output.budgetAllocation;
                                                               }
                                                            })
                                                         }
                                                      })
                                                   }

                                                   let kurs = priorityProgram.kurs || 1;
                                                   form.setFieldValue([name, priorityProgramIndex, "totalAmountRp"], alocation)
                                                   form.setFieldValue([name, priorityProgramIndex, "totalAmount"], alocation / kurs)
                                                }
                                             }}
                                             disabled={disable}
                                          />
                                       </Form.Item>
                                    </div>
                                 </Col>
                              </Row>
                           </Panel>
                        </Collapse>
                     </div>
                  )
               })}

               <Row justify={"end"}>
                  {!disable &&
                     <Col span={3} xs={12} sm={12} md={8} lg={6} xl={4}>
                        <Button
                           icon={<PlusOutlined />}
                           className="btn-primary w-full"
                           disabled={disable}
                           onClick={() => {
                              add()
                           }}
                        >
                           Output
                        </Button>
                     </Col>
                  }
               </Row>
            </>
         )}
      </Form.List>
   )
}