import { Tag } from "antd";

export const tagRender = (props) => {
 const { label, value, closable, onClose } = props;
 const onPreventMouseDown = (event) => {
   event.preventDefault();
   event.stopPropagation();
 };
 return (
   <Tag
     color={value}
     onMouseDown={onPreventMouseDown}
     closable={closable}
     onClose={onClose}
     style={{
       marginRight: 3,
       background: "#01A24A"
     }}
   >
     {label}
   </Tag>
 );
};