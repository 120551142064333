import { useEffect } from 'react';
import {
  Button,
  Modal,
  Col,
  Row,
  Form,
  Input,
  Select,
} from 'antd';

const ModalParameter = ({
  open,
  handleOk,
  handleCancel,
  form,
  name,
  priorityProgramIndex,
  outcomeIndex,
  outputIndex,
  subOutputIndex,
  indicatorIndex,
  parameterIndex,
  parameterValue,
  listParameter,
  disable,
  remove
}) => {
  const [formParameter] = Form.useForm();

  useEffect(() => {
    console.log(parameterValue)
    if(parameterValue){
      formParameter.setFieldsValue({ 'originalId': parameterValue.originalId });
      formParameter.setFieldsValue({ 'parameterName': parameterValue.parameterName });
      formParameter.setFieldsValue({ 'target': parameterValue.target });
      formParameter.setFieldsValue({ 'unit': parameterValue.unit });
      formParameter.setFieldsValue({ 'indicativeActivities': parameterValue.indicativeActivities });
      formParameter.setFieldsValue({ '_indicativeActivities': parameterValue._indicativeActivities });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameterValue])

  const cancelModal = () => {
    handleCancel();

    if(parameterValue?.modalTitle === "Tambah"){
      parameterValue = undefined;
      remove(parameterIndex)
    } else {
      if (name === "priorityPrograms") {
        form.setFieldValue([name, priorityProgramIndex, "impact", "outcomes", outcomeIndex, "outputs", outputIndex, "subOutputs", subOutputIndex, "indicators", indicatorIndex, "parameters", parameterIndex], parameterValue)
      } else {
        form.setFieldValue([name, priorityProgramIndex, "outcomes", outcomeIndex, "outputs", outputIndex, "subOutputs", subOutputIndex, "indicators", indicatorIndex, "parameters", parameterIndex], parameterValue)
      }
    }
  }

  return (
    <>
      <Modal
        title={`${parameterValue?.modalTitle} Parameter`}
        onCancel={cancelModal}
        centered
        open={open}
        footer={[
          <hr />,
          <div className="flex justify-center mt-4">
            <Button
              onClick={cancelModal}
              size="large"
              className="font-medium"
              style={{ width: "290px" }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              className="font-medium"
              style={{ width: "290px" }}
              onClick={() => {
                let formParameterValue = formParameter.getFieldsValue();
                console.log(formParameterValue);
                handleOk();
                if (name === "priorityPrograms") {
                  form.setFieldValue([name, priorityProgramIndex, "impact", "outcomes", outcomeIndex, "outputs", outputIndex, "subOutputs", subOutputIndex, "indicators", indicatorIndex, "parameters", parameterIndex], formParameterValue)
                } else {
                  form.setFieldValue([name, priorityProgramIndex, "outcomes", outcomeIndex, "outputs", outputIndex, "subOutputs", subOutputIndex, "indicators", indicatorIndex, "parameters", parameterIndex], formParameterValue)
                }
              }}
            >
              Submit
            </Button>
          </div>
        ]}
        width={640}
        style={{ bottom: 50 }}
      >
        <hr />
        <Form
          form={formParameter}
          name="form-parameter"
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          scrollToFirstError={true}
        >
          <Row gutter={12}>
            <Col span={24} className="mt-4">
              <div className="">
                <label className="font-medium">Parameter</label>
                <Form.Item
                  name={["parameterName"]}
                >
                  <Select
                    placeholder="Parameter"
                    type="text"
                    className="mt-2"
                    options={listParameter || []}
                    disabled={disable}
                    onChange={(value, option) => {
                      formParameter.setFieldValue(["originalId"], option.id || "3545536d-db8d-46dd-a9e5-339818742980")
                      formParameter.setFieldValue(["parameterName"], value)
                      formParameter.setFieldValue(["unit"], option.satuan)
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={["originalId"]}
                ></Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="">
                <label className="font-medium">Target</label>
                <Form.Item
                  name={["target"]}
                >
                  <Input
                    placeholder="0"
                    type="text"
                    className="form-input mt-4"
                    disabled={disable}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="">
                <label className="font-medium">Satuan</label>
                <Form.Item
                  name={["unit"]}
                >
                  <Input
                    placeholder="Satuan"
                    type="text"
                    className="form-input mt-4"
                    disabled
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="">
                <label className="font-medium">Indicative Activity </label>
                <Form.Item
                  name={["_indicativeActivities"]}
                >
                  <Select
                    className="mt-2"
                    type="text"
                    mode="multiple"
                    options={[
                      { value: 'Default Activity', label: 'Default Activity', id: "3545536d-db8d-46dd-a9e5-339818742980" },
                      { value: 'Activity A', label: 'Activity A', id: "3545536d-db8d-46dd-a9e5-339818742980" },
                      { value: 'Activity B', label: 'Activity B', id: "3545536d-db8d-46dd-a9e5-339818742980" },
                      { value: 'Activity C', label: 'Activity C', id: "3545536d-db8d-46dd-a9e5-339818742980" },
                    ]}
                    disabled={disable}
                    onChange={(value, option) => {
                      let activities = [];
                      value.forEach((item, idx) => {
                        activities.push({
                          originalId: option[idx].id,
                          indicativeActivityName: item
                        })
                      })

                      formParameter.setFieldValue(["indicativeActivities"], activities)
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={["indicativeActivities"]}
                ></Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>

    </>
  );
};

export default ModalParameter;