import {
    ADD_OIDC_USER,
    REMOVE_OIDC_USER
  } from '../actions';
  
  const initialState = {
    oidc_user: null
  };
  
  export const oidcReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_OIDC_USER:
        return {
          ...state,
          oidc_user: action.payload
        }
      case REMOVE_OIDC_USER:
        return {
          ...state,
          oidc_user: action.payload
        }
      default:
        return state
    }
  };