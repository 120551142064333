import React from "react";
import { AuthConsumer } from "../../providers/authProvider";

export const SignoutCallback = () => (
    <AuthConsumer>
        {({ signoutRedirectCallback }) => {
            signoutRedirectCallback();
            return <span>loading</span>;
        }}
    </AuthConsumer>
);