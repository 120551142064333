import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Select } from "antd";
import {
   PlusOutlined,
   DeleteOutlined
} from '@ant-design/icons';
import axios from "axios";
import { MASTER_DATA_URL } from "services/config";
import { IndicatorThematicForm } from "./IndicatorThematicForm";
import { ReactComponent as Confirmation } from "../../../assets/logo/Confirmation.svg"

export const SubOutputForm = ({ Form, form, disable, name, priorityProgramIndex, outcomeIndex, outputIndex }) => {
   const [listSubOutput, setListSubOutput] = useState([]);

   const requiredField = [{ required: true, message: 'Wajib Diisi' }];

   const loadListSubOutput = () => {
      axios.get(`${MASTER_DATA_URL}/SubOutput/list`)
         .then(res => {
            if (res.data) {
               let arr = [];

               res.data.data?.forEach(item => {
                  if (item.idOutput === checkOutputValue("originalId")) {
                     arr.push({
                        ...item,
                        key: item.id,
                        value: item.numberingFormat + " " + item.nama,
                        label: item.numberingFormat + " " + item.nama,
                     })
                  }
               })

               setListSubOutput(arr);
            }
         })
         .catch(err => {
            console.log(err)
         })
   }

   useEffect(() => {
      loadListSubOutput();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [form.getFieldsValue(true)])


   const checkOutputValue = (key) => {
      let obj = form.getFieldsValue([name]);
      let priorityPrograms = obj[name];

      let output;
      if (name === "priorityPrograms") {
         output = priorityPrograms[priorityProgramIndex].impact.outcomes[outcomeIndex].outputs[outputIndex];
      } else {
         output = priorityPrograms[priorityProgramIndex].outcomes[outcomeIndex].outputs[outputIndex];
      }

      if (output) {
         if (key === "all") {
            return output;
         } else {
            return output[key];
         }
      } else {
         return undefined;
      }
   }

   const handleDeleteSubOutput = (remove, idx) => {
		Modal.confirm({
			centered: true,
			title: '',
			icon: <Confirmation />,
			okText: "Confirm",
			bodyStyle: { justifyContent: "center" },
			content: (
				<>
					<h1 className="mb-4" style={{ fontSize: "17px", fontWeight: "600", lineHeight: "24px", justifyContent: "center" }}>
						Anda yakin menghapus Sub Output ini?
					</h1>
				</>
			),
			onOk() {
				remove(idx)
			}
		})
	}

   return (
      <Form.List name={[outputIndex, "subOutputs"]}>
         {(subOutputs, { add, remove }) => (
            <>
               {subOutputs.map((subOutput, subOutputIndex) => {
                  return (
                     <div className={(subOutputIndex > 0) && "mt-2"}>
                        <Row gutter={12} className="">
                           <Col span={disable ? 24 : 21}
                              xs={disable ? 24 : 12}
                              sm={disable ? 24 : 12}
                              md={disable ? 24 : 16}
                              lg={disable ? 24 : 18}
                              xl={disable ? 24 : 20}
                           >
                              <div>
                                 <label className="field-required font-medium">Sub Output</label>&nbsp;
                                 {subOutputIndex > 0 &&
                                    <span><DeleteOutlined onClick={() => { handleDeleteSubOutput(remove, subOutputIndex) }} /></span>
                                 }
                                 <Form.Item
                                    name={[subOutputIndex, 'subOutputName']}
                                    rules={requiredField}
                                 >
                                    <Select
                                       placeholder="Sub Output"
                                       type="text"
                                       className="mt-2"
                                       disabled={disable}
                                       options={listSubOutput}
                                       onChange={(value, option) => {
                                          let obj = form.getFieldsValue([name]);
                                          let priorityPrograms = obj[name];

                                          let subOutput;
                                          subOutput = priorityPrograms[priorityProgramIndex].impact.outcomes[outcomeIndex].outputs[outputIndex].subOutputs[subOutputIndex];

                                          subOutput = {
                                             ...subOutput,
                                             originalId: option.id,
                                             outputName: value
                                          }

                                          if (name === "priorityPrograms") {
                                             form.setFieldValue([name, priorityProgramIndex, "impact", "outcomes", outcomeIndex, "outputs", outputIndex, "subOutputs", subOutputIndex], subOutput)
                                          } else {
                                             form.setFieldValue([name, priorityProgramIndex, "outcomes", outcomeIndex, "outputs", outputIndex, "subOutputs", subOutputIndex], subOutput)
                                          }
                                       }}
                                    />
                                 </Form.Item>
                              </div>
                           </Col>
                           {!disable &&
                              <Col span={3} xs={12} sm={12} md={8} lg={6} xl={4}>
                                 <Button
                                    icon={<PlusOutlined />}
                                    className="btn-primary mt-8 w-full"
                                    disabled={disable}
                                    onClick={() => {
                                       let obj = form.getFieldsValue([name]);
                                       let priorityPrograms = obj[name];

                                       let subOutputs;
                                       if (name === "priorityPrograms") {
                                          subOutputs = priorityPrograms[priorityProgramIndex].impact.outcomes[outcomeIndex].outputs[outputIndex].subOutputs;
                                       } else {
                                          subOutputs = priorityPrograms[priorityProgramIndex].outcomes[outcomeIndex].outputs[outputIndex].subOutputs;
                                       }

                                       if (subOutputs[subOutputIndex]) {
                                          if (subOutputs[subOutputIndex].indicators) {
                                             subOutputs[subOutputIndex].indicators.push({
                                                indicatorName: null,
                                             })
                                          } else {
                                             subOutputs[subOutputIndex] = {
                                                ...subOutputs[subOutputIndex],
                                                indicators: [{
                                                   indicatorName: null
                                                }]
                                             }
                                          }
                                       } else {
                                          subOutputs[subOutputIndex] = { ...subOutputs[subOutputIndex], indicators: [{ parameters: [] }] }
                                       }

                                       if (name === "priorityPrograms") {
                                          form.setFieldValue([name, priorityProgramIndex, "impact", "outcomes", outcomeIndex, "outputs", outputIndex, "subOutputs"], subOutputs)
                                       } else {
                                          form.setFieldValue([name, priorityProgramIndex, "outcomes", outcomeIndex, "outputs", outputIndex, "subOutputs"], subOutputs)
                                       }
                                    }}
                                 >
                                    Indicator
                                 </Button>
                              </Col>
                           }
                        </Row>
                        <IndicatorThematicForm
                           Form={Form}
                           form={form}
                           disable={disable}
                           name={name}
                           priorityProgramIndex={priorityProgramIndex}
                           outcomeIndex={outcomeIndex}
                           outputIndex={outputIndex}
                           subOutputIndex={subOutputIndex}
                        />
                     </div>
                  )
               })}
            </>
         )}
      </Form.List>
   )
}