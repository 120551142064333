// import { Label } from "components";
import React from "react";

const UnderDevelopment = () => {
 return (
  <div className="flex items-center justify-center" style={{height: "calc(100vh - 120px)", color: "#01A24A"}}>
   <div className="block">
    <div className="flex items-center justify-center text-4xl font-bold" >Coming Soon... </div><br />
    <div className="flex items-center justify-center text-4xl font-bold" >This Page is under Development </div>
   </div>
  </div>
 );
};

export default UnderDevelopment;
