import axios from 'axios';
import { BASE_URL } from 'services/config';

const token = localStorage.getItem('token');

// Set config defaults when creating the instance
const Axios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

Axios.defaults.headers.common.Authorization = `Bearer ${token}`;

export const getDonorList = () => {
  return axios.get(`${BASE_URL}/donor/list`);
};

export const getTotalPenghimpunanDana = () => {
  return Axios.get(`/dashboard/total-penghimpunan-dana`);
};

export const getPenghimpunanDanaByProposal = async () => {
  return Axios.get(`/dashboard/penghimpunan-dana-by-proposal`);
};

export const getPenghimpunanDanaByFinancialInstrument = async () => {
  return Axios.get(`/dashboard/penghimpunan-dana-by-financial-instrument`);
};

export const getDonorCount = async () => {
  return Axios.get(`/dashboard/donor-count`);
};

export const getNilaiKomitmenByDonor = async (donorId) => {
  return Axios.get(`/dashboard/nilai-komitmen-by-donor/${donorId}`);
};

export const getProposalCountByType = async () => {
  return Axios.get(`/dashboard/proposal-count-by-type`);
};

export const getSubmissionProposalCountByStatus = async () => {
  return Axios.get(`/dashboard/submission-proposal-count-by-status`);
};

export const getTopDonors = async () => {
  return Axios.get(`/dashboard/top-donors`);
};
