import React, { useState } from 'react';
import { Form, Button, Space, notification, Spin, Row, Col } from 'antd';
import '../index.scss';
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';
import Icon, { RightOutlined } from '@ant-design/icons';

import { BASE_URL } from 'services/config';
import { DownloadAbleIcon } from 'components/atoms/SVG';
import { useEffect } from 'react';
import { downloadFile } from 'utils';
import moment from 'moment';

const HomeLine = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
      stroke="#667085"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const DetailRekening = () => {
  const params = useParams();
  const [form] = Form.useForm();
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

  const [loading, setLoading] = useState(false);

  // const [officialMemo, setOfficialMemo] = useState();
  const [dataRekening, setDataRekening] = useState();

  const queryParams = new URLSearchParams(window.location.search);
  const role = queryParams.get('role');
  const group = queryParams.get('group');

  useEffect(() => {
    if (params.id !== 'new') {
      loadDetailDataRekening();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDetailDataRekening = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/bank-account/${params.id}?role=${role}&group=${group}`)
      .then((res) => {
        setLoading(false);
        let rekening = res.data.result;
        setDataRekening(rekening);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({
          message: 'Kesalahan',
          duration: 2,
          description: err.message,
        });
      });
  };
  let doc = {};

  const handleAmount = (data) => {
    switch (dataRekening?.currency) {
      case 'IDR':
        return `Rp ${data.amount?.toLocaleString('id-ID', {
          maximumFractionDigits: 0,
        })}`;
      case 'EURO':
        return `€ ${data.amount?.toLocaleString('id-ID', {
          maximumFractionDigits: 0,
        })}`;
      case 'USD':
        return `$ ${data.amount?.toLocaleString('id-ID', {
          maximumFractionDigits: 0,
        })}`;
      default:
        return `Rp ${data.amount?.toLocaleString('id-ID', {
          maximumFractionDigits: 0,
        })}`;
    }
  };

  return (
    <>
      <Spin spinning={loading} indicator={spinIcon}>
        <div className="px-4">
          <Form
            form={form}
            name="form-create-donor"
            // onFinish={onFinish}
            initialValues={{
              pic: [{}],
              sectors: [{}],
            }}
          >
            <div className="flex items-center py-5 flex-wrap">
              <Space>
                <Icon component={HomeLine} className="mr-2" />
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <p className="font-medium mr-3">Daftar Rekening</p>
              </div>
              <Space>
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <Button
                  style={{
                    background: '#EEFFF4',
                    color: '#01A24A',
                    borderRadius: '8px',
                    border: 'none',
                  }}
                  className="flex justify-center items-center"
                >
                  <span className="font-medium text-center">
                    {params.id !== 'new' ? 'Detail' : 'Create'}
                  </span>
                </Button>
              </div>
            </div>

            <div
              className="w-100 list overflow-hidden mb-6"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div className="">
                <div
                  className=""
                  style={{ background: '#00BD52', height: '10px' }}
                ></div>
                <div className="px-5 py-5">
                  <p className="text-2xl font-medium">Informasi Donor</p>
                </div>
                <hr />
                <div className="px-5 mt-4 mb-4">
                  <Row className="gap-4" gutter={24}>
                    <Col span={4}>
                      <div className="mb-4">
                        <p className="text-sub-heading">Nama Donor</p>
                        <span className="text-body">
                          {dataRekening?.donor?.name || '-'}
                        </span>
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="mb-4">
                        <p className="text-sub-heading">Program Tematik</p>
                        {dataRekening?.donor?.sectors
                          ? dataRekening?.donor?.sectors.map((sector) => (
                              <span className="text-body">
                                {sector.name || '-'}
                                <br />
                              </span>
                            ))
                          : '-'}
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="mb-4">
                        <p className="text-sub-heading">No Telepon</p>
                        {dataRekening?.donor?.phoneNumbers
                          ? dataRekening?.donor?.phoneNumbers.map(
                              (phoneNumber) => (
                                <span className="text-body">
                                  {phoneNumber || '-'}
                                  <br />
                                </span>
                              )
                            )
                          : '-'}
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="mb-4">
                        <p className="text-sub-heading">Email</p>
                        {dataRekening?.donor?.emails
                          ? dataRekening?.donor?.emails.map((email) => (
                              <span className="text-body">
                                {email || '-'}
                                <br />
                              </span>
                            ))
                          : '-'}
                      </div>
                    </Col>
                    <Col span={6}>
                      <div>
                        <p className="text-sub-heading">Website</p>
                        <span className="text-body">
                          {dataRekening?.donor?.website || '-'}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
                <hr className="mb-4 mt-4" />
              </div>
            </div>

            <div
              className="w-100 list overflow-hidden mb-6"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div className="">
                <div
                  className=""
                  style={{ background: '#00BD52', height: '10px' }}
                ></div>
                <div className="px-5 py-5">
                  <p className="text-2xl font-medium">Informasi Dana</p>
                </div>
                <hr />

                <div
                  style={{
                    background: '#EEFFF4',
                    color: '#01A24A',
                    borderRadius: '8px',
                    border: 'none',
                  }}
                  className="flex items-center mx-5 my-5 px-5 py-5"
                >
                  <span className="font-medium text-2xl text-left">
                    No. Kontrak ({dataRekening?.contractNumber || '-'})
                  </span>
                </div>

                <div className="px-5 mt-4 mb-4">
                  <Row className="gap-4" gutter={24}>
                    <Col span={4}>
                      <div className="mb-4">
                        <p className="text-sub-heading">Detail Rekening</p>
                        {dataRekening?.officialMemo ? (
                          <div
                            className="flex justify-start mr-4"
                            style={{
                              border: '1px solid #EAECF0',
                              borderRadius: '12px',
                              padding: '16px',
                              width: '335px',
                            }}
                          >
                            <div className="mr-2">
                              <Icon component={DownloadAbleIcon} />
                            </div>

                            <div className="row mr-10">
                              <p className="wrap-text bold">
                                <b>{'Dokumen Koran'}</b>
                              </p>
                              <p className="wrap-text bold">
                                {dataRekening?.officialMemo?.fileName ||
                                  'dokumenkoran.pdf'}{' '}
                              </p>{' '}
                              <span>
                                ({dataRekening?.officialMemo?.fileSize} KB)
                              </span>
                              <div className="flex justify-between">
                                <strong
                                  style={{
                                    color: '#00BD52',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    window.open(
                                      dataRekening?.officialMemo?.url,
                                      '_blank'
                                    );
                                  }}
                                >
                                  Buka
                                </strong>
                                <strong
                                  style={{
                                    color: '#00BD52',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    downloadFile(
                                      dataRekening?.officialMemo?.url,
                                      dataRekening?.officialMemo?.fileName
                                    );
                                  }}
                                >
                                  Unduh
                                </strong>
                              </div>
                            </div>
                          </div>
                        ) : (
                          '-'
                        )}
                      </div>
                    </Col>
                  </Row>

                  <hr className="mb-4 mt-4" />

                  <Row>
                    <Col span={4}>
                      <div className="mb-4">
                        <p className="text-sub-heading">Mata Uang</p>
                        {dataRekening?.currency || '-'}
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="mb-4">
                        <p className="text-sub-heading">Nama Bank</p>
                        {dataRekening?.bankName || '-'}
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="mb-4">
                        <p className="text-sub-heading">Jumlah Komitmen</p>
                        {dataRekening ? handleAmount(dataRekening) : '-'}
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="mb-4">
                        <p className="text-sub-heading">Periode Project</p>
                        {dataRekening?.period + ' Bulan' || '-'}
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="mb-4">
                        <p className="text-sub-heading">Nama Rekening</p>
                        {dataRekening?.accountName || '-'}
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="mb-4">
                        <p className="text-sub-heading">No. Rekening</p>
                        {dataRekening?.accountNumber || '-'}
                      </div>
                    </Col>
                  </Row>
                </div>

                <hr className="mb-4 mt-4" />

                {dataRekening?.payments &&
                  dataRekening?.payments.length > 0 && (
                    <div className="px-5 mt-4 mb-4">
                      <Row className="gap-4" gutter={24}>
                        <Col span={4}>
                          <div className="mb-4">
                            <p className="text-sub-heading">Detail Dana</p>
                            <div
                              className="flex justify-start mr-4"
                              style={{
                                border: '1px solid #EAECF0',
                                borderRadius: '12px',
                                padding: '16px',
                                width: '335px',
                              }}
                            >
                              <div className="mr-2">
                                <Icon component={DownloadAbleIcon} />
                              </div>

                              <div className="row mr-10">
                                <p className="wrap-text bold">
                                  <b>{'Dokumen Koran'}</b>
                                </p>
                                <p className="wrap-text bold">
                                  {doc.fileName || 'dokumenkoran.pdf'}{' '}
                                </p>{' '}
                                <span>({doc.fileSize}100 KB)</span>
                                <div className="flex justify-between">
                                  <strong
                                    style={{
                                      color: '#00BD52',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      window.open(doc.url, '_blank');
                                    }}
                                  >
                                    Buka
                                  </strong>
                                  <strong
                                    style={{
                                      color: '#00BD52',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      downloadFile(doc.url, doc.fileName);
                                    }}
                                  >
                                    Unduh
                                  </strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <hr className="mb-4 mt-4" />

                      {dataRekening.payments.map((payment) => (
                        <Row className="gap-4" gutter={24}>
                          <Col span={4}>
                            <div className="mb-4">
                              <p className="text-sub-heading">Nama Donor</p>
                              {dataRekening?.donor?.name || '-'}
                            </div>
                          </Col>
                          <Col span={4}>
                            <div className="mb-4">
                              <p className="text-sub-heading">
                                Nama Rekening Pengirim
                              </p>
                              {payment?.accountName || '-'}
                            </div>
                          </Col>
                          <Col span={4}>
                            <div className="mb-4">
                              <p className="text-sub-heading">
                                No Rekening Pengirim
                              </p>
                              {payment?.accountNumber || '-'}
                            </div>
                          </Col>
                          <Col span={4}>
                            <div className="mb-4">
                              <p className="text-sub-heading">
                                Tanggal & Waktu
                              </p>
                              {payment?.date
                                ? moment(payment?.date).format(
                                    'DD/MM/YYYY HH:mm'
                                  )
                                : '-'}
                            </div>
                          </Col>
                          <Col span={4}>
                            <div className="mb-4">
                              <p className="text-sub-heading">Jumlah Dana</p>
                              {payment?.amount ? handleAmount(payment) : '-'}
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  )}

                <hr className="mb-4 mt-6" />
              </div>
            </div>

            <div
              className="list mt-6 px-4"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div className="flex justify-between items-center">
                <Space>
                  <NavLink to={'/rekening'}>
                    <Button
                      style={{
                        borderRadius: '8px',
                        border: '1px solid #EAECF0',
                        width: '120px',
                        height: '50px',
                      }}
                      icon={<ArrowLeftOutlined />}
                      className="mt-4 mb-4 flex justify-center items-center font-medium"
                    >
                      <span className="ml-2 text-center">Kembali</span>
                    </Button>
                  </NavLink>
                </Space>
              </div>
            </div>
          </Form>
        </div>
      </Spin>
    </>
  );
};

export default DetailRekening;
