// import React from "react";
// import { motion } from "framer-motion";

// const AuthLayout = ({ children }) => {
//   return (
//     <motion.div
//       className="flex flex-1 justify-center items-center h-screen bg-slate-100"
//       exit={{ opacity: 0 }}
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1 }}
//       transition={{ delay: 0.05 }}
//     >
//       {children}
//     </motion.div>
//   );
// };

// export default AuthLayout;

import { Suspense, useLayoutEffect } from "react";
import { useLoaderData, Await, useLocation, Outlet, useOutletContext } from "react-router-dom";
import { Spinner } from "components";

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const AuthLayout = () => {
  const userData = useLoaderData();
  return (
    <Suspense fallback={<Spinner />}>
      <Await
        resolve={userData}
        errorElement={<div className="text-error">Something went wrong!</div>}
        children={({ userAccessRights }) => (
          <ScrollToTop>{<Outlet context={{ userAccessRights }} />}</ScrollToTop>
        )}
      />
    </Suspense>
  );
};

export const useAccessRights = () => useOutletContext();

export default AuthLayout;
