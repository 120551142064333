/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from 'react';
import {
	Space,
	Table,
	Button,
	Input,
	Spin,
	Pagination,
	Select,
	Tag,
} from 'antd';
import Icon, {
	SearchOutlined,
	EyeOutlined,
	RightOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import "../index.scss";
import { BASE_URL, OIDC } from 'services/config';
import { GreenDotIcon, HomeLined, LeftArrow, OrangeDotIcon, RightArrow } from 'components/atoms/SVG';
import store from 'app/store';

const ListRekap = () => {
	let navigate = useNavigate();
	const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

	const [dataProposal, setDataProposal] = useState([]);
	const [loading, setLoading] = useState();
	const [opt, setOpt] = useState(10);
	const [page] = useState(1);
	const [paginator, setPaginator] = useState();
	const [current, setCurrent] = useState(1);
	const [limit, setLimit] = useState(10);

	const [statusList] = useState([
		{ value: 'proposalOngoing', label: 'Proposal - Ongoing' },
		{ value: 'contractOngoing', label: 'Kontrak - Ongoing' },
		{ value: 'bankAccountOngoing', label: 'Rekening - Ongoing' },
		{ value: 'done', label: 'Selesai' },
	])

	const oidc_user = store.getState().oidc.oidc_user;
	const user = JSON.parse(oidc_user).profile;

	// HANDLE ROLE
	const handleRole = () => {
		let _role = user?.Jabatan + "-" + user?.Directorat;

		if (user?.DirectoratCode === "004") {
			_role = "staff-ppd";
		}

		switch (_role) {
			case "staff-ppd":
				return ({ title: "staff", group: "ppd" });
			case "direktur-ppd":
				return ({ title: "director", group: "ppd" });
			case "staff-kl":
				return ({ title: "staff", group: "kl" });
			case "direktur-kl":
				return ({ title: "director", group: "kl" });
			case "direktorat-lain":
				return ({ title: "director", group: "otherDirectorate" });
			case "direktur-utama":
				return ({ title: "director", group: "presidentDirector" });
			default:
				return ({ title: "director", group: "presidentDirector" });
		}
	}

	const [role] = useState(handleRole());
	const [searchKey, setSearchKey] = useState("");
	const [searchStatus, setSearchStatus] = useState("");
	const [searchStartDate, setSearchStartDate] = useState("");
	const [searchEndDate, setSearchEndDate] = useState("");
	const [sortDir, setSortDir] = useState();
	const [sortBy, setSortBy] = useState();

	const loadDataProposal = () => {
		setLoading(true);

		let path = `${BASE_URL}/recap?page=${current}&limit=${limit}&role=${role.title}&group=${role.group}`

		if (searchKey) {
			path = path + `&search=${searchKey}`
		}

		if (searchStatus) {
			path = path + `&status=${searchStatus}`
		}

		if (searchStartDate) {
			path = path + `&startDate=${searchStartDate}`
		}

		if (searchEndDate) {
			path = path + `&endDate=${searchEndDate}`
		}

		if (sortDir) {
			path = path + `&sort=${sortDir.replace("end", "")}`
		}

		if (sortBy) {
			path = path + `&sortBy=${sortBy}`
		}

		axios.get(path)
			.then(res => {
				setDataProposal(res.data.result);
				setLoading(false);
				setPaginator(res.data.paginator);
			});
	};

	useMemo(() => {
		loadDataProposal()
	}, [searchKey, searchStatus, searchStartDate, searchEndDate, sortDir, sortBy, current, limit])

	const onSearch = (e) => {
		if (e.key === 'Enter') {
			setCurrent(1);
			setSearchKey(e.target.value);
		}
	};

	const onSearchStatus = (status) => {
		setCurrent(1);
		setSearchStatus(status);
	};

	function onPaginationChange(limit) {
		setOpt(limit);
		setLimit(limit);
		setCurrent(1);
	}

	function onNumberPaginationChange(changePage) {
		setCurrent(changePage);
	}

	function onSortChange(_sortDir, _sortBy) {
		setCurrent(1);
		setSortBy(_sortBy);
		setSortDir(_sortDir);
	}

	function itemRender(current, type, originalElement) {
		if (type === 'prev') {
			return <span className="">
				<Icon component={LeftArrow} />
				Previous
			</span>;
		}
		if (type === 'next') {
			return <span className="">
				Next
				<Icon component={RightArrow} />
			</span>;
		}
		return originalElement;
	}

	function typeList(value) {
		let tags = [];

		switch (value?.status) {
			case 'Proposal - Ongoing':
				tags.push(
					tags = { colour: "#FFFAEB", text: "Proposal - Ongoing", textColor: "#B54708", dotColor: OrangeDotIcon }
				);
				break;
			case 'Kontrak - Ongoing':
				tags.push(
					tags = { colour: "#FFFAEB", text: "Kontrak - Ongoing", textColor: "#B54708", dotColor: OrangeDotIcon }
				);
				break;
			case 'Rekening - Ongoing':
				tags.push(
					tags = { colour: "#FFFAEB", text: "Rekening - Ongoing", textColor: "#B54708", dotColor: OrangeDotIcon }
				);
				break;
			case 'Selesai':
				tags.push(
					tags = { colour: "#ECFDF3", text: "Selesai", textColor: "#1C7D44", dotColor: GreenDotIcon }
				);
				break;
			default:
		}

		return <div className="flex mb-2">
			<Tag
				color={tags.colour}
				style={{ color: tags.textColor }}
				className="flex pl-2 pr-2 items-center"
				icon={<Icon component={tags.dotColor} className="" />}
			>
				{
					tags.text
				}
			</Tag>
		</div>
	}

	function onClickDetail(rekap) {
		return navigate(`/rekap/detail-rekap/${rekap}`);
	}

	function proposalStatus(value) {
		return <div className="status">
			<Button type="ghost" style={{ border: "none", marginBottom: "3px" }} onClick={() => onClickDetail(value._id)}>{<EyeOutlined style={{ fontSize: "18px" }} />}</Button>
		</div>
	}

	const columns = [
		{
			title: 'No',
			key: 'number',
			width: '68px',
			height: '72px',
			render: (val, text, data) => {
				return <p>{data + 1 + ((current - 1) * limit)}</p>
			}
		},
		{
			title: 'Nama Donor',
			width: '400px',
			sorter: (a, b, sortDir) => { onSortChange(sortDir, "donor") },
			render: (value) => <span>{value.donor}</span>,
		},
		{
			title: 'Judul Proposal',
			width: '400px',
			sorter: (a, b, sortDir) => { onSortChange(sortDir, "title") },
			render: (value) => <span>{value.title}</span>,
		},
		{
			title: 'Status',
			key: 'status',
			width: '600px',
			sorter: (a, b, sortDir) => { onSortChange(sortDir, "status") },
			render: (value) => <>
				<span>{typeList(value)}</span>
			</>
		},
		{
			title: 'Aksi',
			key: 'action',
			width: '300px',
			align: 'center',
			render: (value) => (proposalStatus(value))
		}
	];

	return (
		<>
			<div className="flex items-center py-4 flex-wrap">
				<Space>
					<Icon component={HomeLined} className="mr-2" />
					<RightOutlined
						className="mr-3 text-xs right-cursor"
					/>
				</Space>
				<div>
					<p className="font-medium" style={{ color: "#01A24A" }}>Dokumen Penghimpunan</p>
				</div>
			</div>
			<div className="flex items-center justify-between mb-4">
				<h1 className="text-2xl font-medium">Dokumen Penghimpunan</h1>
			</div>
			<div className="table-list" style={{ border: "1px solid #EAECF0", borderRadius: "12px" }}>
				<div className="flex gap-4 py-6 px-6">
					<Input
						size="large"
						placeholder="Cari"
						className="mt-2 pl-2"
						prefix={<SearchOutlined />}
						onKeyDown={(e) => onSearch(e)}
						style={{ height: "48px" }}
					/>
					<Select
						size="small"
						placeholder="Status"
						allowClear
						type="text"
						className="mt-2"
						style={{ height: "48px", width: '95%' }}
						options={statusList}
						onKeyDown={(e) => onSearchStatus(e)}
						onChange={(e) => onSearchStatus(e)}
					/>
				</div>
				<div>
					<Spin spinning={loading} indicator={spinIcon}>
						<Table
							columns={columns}
							dataSource={dataProposal}
							pagination={false}
							onChange={onSearch}
							className="px-5"
							style={{ overflowX: "auto" }}
						/>
						<div className="flex table-paginator-wrapper my-3 px-2 justify-between">
							<div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
								<div className="show align-self-center pr-2 font-medium text-sm">
									{'Tampilkan Data'}
								</div>
								<div className="dropdown-paging align-self-center pr-2">
									<Space direction="vertical" style={{ width: '100%' }}>
										<Select
											value={opt}
											className="select-paging-ppd"
											showArrow={true}
											style={{ width: "60px" }}
											options={[
												{ value: 5, label: 5 },
												{ value: 10, label: 10 },
												{ value: 30, label: 30 },
												{ value: 50, label: 50 },
												{ value: 100, label: 100 },
											]}
											onChange={onPaginationChange}
										/>
									</Space>
								</div>
								<div className="border-paging align-self-center pr-2"></div>
								<div className="total align-self-center pr-2">
									{
										`${dataProposal.slice(0, 1).length} - ${dataProposal.length} data dari ${paginator?.itemCount} data`
									}
								</div>
							</div>
							<div>
								<Pagination
									pageSize={opt || 10}
									current={current || page}
									total={paginator?.itemCount}
									onChange={onNumberPaginationChange}
									itemRender={itemRender}
								/>
							</div>
						</div>
					</Spin>
				</div>
			</div>
		</>
	);
}

export default ListRekap;