import ForgotPassword from "pages/ForgotPassword";
import User from "pages/User";

// Data Donor Menu
import CreateDanaProgram from "pages/ListDonor/Create"
import ListDataDonor from "pages/ListDonor/List";
import DetailDataDonor from "pages/ListDonor/Detail";
import EditDanaProgram from "pages/ListDonor/Edit";

// Data Proposal Submission
import ProposalSubmissionList from "pages/ProposalSubmission/List/index";
import CreateProposalSubmissionStepOne from "pages/ProposalSubmission/Create/Step1";
import CreateProposalSubmissionStepTwo from "pages/ProposalSubmission/Create/Step2";
import CreateProposalSubmissionStepThree from "pages/ProposalSubmission/Create/Step3";
import CreateProposalSubmissionStepFour from "pages/ProposalSubmission/Create/Step4";
import EditProposalSubmission from "pages/ProposalSubmission/Edit/index";
import EditProposalSubmissionDirut from "pages/ProposalSubmission/Edit/index-dirut";

// Data Proposal Non-Submission
import ProposalNonSubmissionList from "pages/ProposalNonSubmission/List/index";
import CreateProposalNonSubmissionStepOne from "pages/ProposalNonSubmission/Create/Step1";
import CreateProposalNonSubmissionStepTwo from "pages/ProposalNonSubmission/Create/Step2";
import CreateProposalNonSubmissionStepThree from "pages/ProposalNonSubmission/Create/Step3";
import CreateProposalNonSubmissionStepFour from "pages/ProposalNonSubmission/Create/Step4";
import EditProposalNonSubmission from "pages/ProposalNonSubmission/Edit/index";
import EditProposalNonSubmissionDirut from "pages/ProposalNonSubmission/Edit/index-dirut";
import UnderDevelopment from "pages/UnderDevelopment";

// Data Kontrak
import ListKontrak from "pages/Kontrak/List";
import DetailKontrak from "pages/Kontrak/Detail";

// Data Dokumen Program

// Data Rekening
import ListRekening from "pages/Rekening/List";
import DetailRekening from "pages/Rekening/Detail";
import CreateRekening from "pages/Rekening/Create";

// Data Rekap
import ListRekap from "pages/Rekap/List";
import DetailRekap from "pages/Rekap/Detail";

import Dashboard from "pages/Dashboard";

const routesName = [
  {
    routeType: "public",
    path: "/dashboard",
    element: Dashboard,
    exact: true,
  },
  {
    routeType: "private",
    path: "/",
    element: ListDataDonor,
    exact: true,
    accessRight: {
      id: "data-donor",
      type: "canView"
    }
  },
  {
    routeType: "private",
    path: "/forgot-password",
    element: ForgotPassword,
    exact: true,
  },
  {
    routeType: "private",
    path: "/user",
    element: User,
    exact: true,
  },

  // Data Donor Menu
  {
    routeType: "private",
    path: "/data-donor",
    element: ListDataDonor,
    exact: true,
    accessRight: {
      id: "data-donor",
      type: "canView"
    }
  },
  {
    routeType: "private",
    path: "/data-donor/tambah-donor",
    element: CreateDanaProgram,
    exact: true,
    accessRight: {
      id: "data-donor",
      type: "canCreate"
    }
  },
  {
    routeType: "private",
    path: `/data-donor/detail/:id`,
    element: DetailDataDonor,
    exact: true,
    accessRight: {
      id: "data-donor",
      type: "canView"
    }
  },
  {
    routeType: "private",
    path: `/data-donor/update/:id`,
    element: EditDanaProgram,
    exact: true,
    accessRight: {
      id: "data-donor",
      type: "canView"
    }
  },

  // PROPOSAL LIST
  {
    routeType: "private",
    path: "/proposal-submission",
    element: ProposalSubmissionList,
    exact: true,
    accessRight: {
      id: "proposal-submission",
      type: "canView"
    }
  },
  {
    routeType: "private",
    path: "/proposal-non-submission",
    element: ProposalNonSubmissionList,
    exact: true,
    accessRight: {
      id: "proposal-non-submission",
      type: "canView"
    }
  },

  // CREATE PROPOSAL SUBMISSION
  {
    routeType: "private",
    path: "/proposal-submission/create-proposal/step/1",
    element: CreateProposalSubmissionStepOne,
    exact: true,
    accessRight: {
      id: "proposal-submission",
      type: "canCreate"
    }
  },

  // CONTINUE DRAFT PROPOSAL SUBMISSION
  {
    routeType: "private",
    path: "/proposal-submission/create-proposal/step/1/:id",
    element: CreateProposalSubmissionStepOne,
    exact: true,
    accessRight: {
      id: "proposal-submission",
      type: "canCreate"
    }
  },
  {
    routeType: "private",
    path: "/proposal-submission/create-proposal/step/2/:id",
    element: CreateProposalSubmissionStepTwo,
    exact: true,
    accessRight: {
      id: "proposal-submission",
      type: "canCreate"
    }
  },
  {
    routeType: "private",
    path: "/proposal-submission/create-proposal/step/3/:id",
    element: CreateProposalSubmissionStepThree,
    exact: true,
    accessRight: {
      id: "proposal-submission",
      type: "canCreate"
    }
  },
  {
    routeType: "private",
    path: "/proposal-submission/create-proposal/step/4/:id",
    element: CreateProposalSubmissionStepFour,
    exact: true,
    accessRight: {
      id: "proposal-submission",
      type: "canCreate"
    }
  },

  // CREATE PROPOSAL SUBMISSION ADENDUM
  {
    routeType: "private",
    path: "/proposal-submission/create-proposal/:id/:addendum",
    element: EditProposalSubmission,
    exact: true,
    accessRight: {
      id: "proposal-submission",
      type: "canUpdate"
    }
  },

  // UPDATE OR REVIEW PROPOSAL SUBMISSION
  {
    routeType: "private",
    path: "/proposal-submission/create-proposal/update/:id",
    element: EditProposalSubmission,
    exact: true,
    accessRight: {
      id: "proposal-submission",
      type: "canUpdate"
    }
  },
  {
    routeType: "private",
    path: "/proposal-submission/view-proposal/:id",
    element: EditProposalSubmission,
    exact: true,
    accessRight: {
      id: "proposal-submission",
      type: "canUpdate"
    }
  },
  {
    routeType: "private",
    path: "/proposal-submission/review-proposal/dirut/:id",
    element: EditProposalSubmissionDirut,
    exact: true,
    accessRight: {
      id: "proposal-submission",
      type: "canUpdate"
    }
  },
  {
    routeType: "private",
    path: "/proposal-submission/view-proposal/dirut/:id",
    element: EditProposalSubmissionDirut,
    exact: true,
    accessRight: {
      id: "proposal-submission",
      type: "canUpdate"
    }
  },

  // CREATE PROPOSAL NON SUBMISSION
  {
    routeType: "private",
    path: "/proposal-non-submission/:action/step/1",
    element: CreateProposalNonSubmissionStepOne,
    exact: true,
    accessRight: {
      id: "proposal-non-submission",
      type: "canCreate"
    }
  },

  // CONTINUE DRAFT OR VIEW PROPOSAL NON SUBMISSION
  {
    routeType: "private",
    path: "/proposal-non-submission/:action/step/1/:id",
    element: CreateProposalNonSubmissionStepOne,
    exact: true,
    accessRight: {
      id: "proposal-non-submission",
      type: "canCreate"
    }
  },
  {
    routeType: "private",
    path: "/proposal-non-submission/:action/step/2/:id",
    element: CreateProposalNonSubmissionStepTwo,
    exact: true,
    accessRight: {
      id: "proposal-non-submission",
      type: "canCreate"
    }
  },
  {
    routeType: "private",
    path: "/proposal-non-submission/:action/step/3/:id",
    element: CreateProposalNonSubmissionStepThree,
    exact: true,
    accessRight: {
      id: "proposal-non-submission",
      type: "canCreate"
    }
  },
  {
    routeType: "private",
    path: "/proposal-non-submission/:action/step/4/:id",
    element: CreateProposalNonSubmissionStepFour,
    exact: true,
    accessRight: {
      id: "proposal-non-submission",
      type: "canCreate"
    }
  },

  // CREATE PROPOSAL NON SUBMISSION ADENDUM
  {
    routeType: "private",
    path: "/proposal-non-submission/create-proposal/:id/:addendum",
    element: EditProposalNonSubmission,
    exact: true,
    accessRight: {
      id: "proposal-non-submission",
      type: "canUpdate"
    }
  },

  // UPDATE OR REVIEW PROPOSAL NON SUBMISSION
  {
    routeType: "private",
    path: "/proposal-non-submission/create-proposal/update/:id",
    element: EditProposalNonSubmission,
    exact: true,
    accessRight: {
      id: "proposal-non-submission",
      type: "canUpdate"
    }
  },
  {
    routeType: "private",
    path: "/proposal-non-submission/view-proposal/:id",
    element: EditProposalNonSubmission,
    exact: true,
    accessRight: {
      id: "proposal-non-submission",
      type: "canUpdate"
    }
  },
  {
    routeType: "private",
    path: "/proposal-non-submission/review-proposal/dirut/:id",
    element: EditProposalNonSubmissionDirut,
    exact: true,
    accessRight: {
      id: "proposal-non-submission",
      type: "canUpdate"
    }
  },
  {
    routeType: "private",
    path: "/proposal-non-submission/view-proposal/dirut/:id",
    element: EditProposalNonSubmissionDirut,
    exact: true,
    accessRight: {
      id: "proposal-non-submission",
      type: "canUpdate"
    }
  },

  {
    routeType: "private",
    path: "/proposal-non-submission/create-proposal/step/1",
    element: CreateProposalNonSubmissionStepOne,
    exact: true,
    accessRight: {
      id: "proposal-non-submission",
      type: "canUpdate"
    }
  },

  // DATA KONTRAK
  {
    routeType: "private",
    path: "/perjanjian",
    element: ListKontrak,
    exact: true,
    accessRight: {
      id: "kontrak",
      type: "canView"
    }
  },

  {
    routeType: "private",
    path: "/perjanjian/create-kontrak/:id",
    element: DetailKontrak,
    exact: true,
    accessRight: {
      id: "kontrak",
      type: "canUpdate"
    }
  },

  {
    routeType: "private",
    path: "/perjanjian/detail-kontrak/:id",
    element: DetailKontrak,
    exact: true,
    accessRight: {
      id: "kontrak",
      type: "canUpdate"
    }
  },

  // Data Dokumen Program
  {
    routeType: "private",
    path: "/dokumen-program",
    element: UnderDevelopment,
    exact: true,
    accessRight: {
      id: "dokumen-program",
      type: "canView"
    }
  },

  // Data Rekening
  {
    routeType: "private",
    path: "/rekening",
    element: ListRekening,
    exact: true,
    accessRight: {
      id: "rekening",
      type: "canView"
    }
  },

  {
    routeType: "private",
    path: "/rekening/create-rekening/:id",
    element: CreateRekening,
    exact: true,
    accessRight: {
      id: "rekening",
      type: "canCreate"
    }
  },

  {
    routeType: "private",
    path: "/rekening/detail-rekening/:id",
    element: DetailRekening,
    exact: true,
    accessRight: {
      id: "rekening",
      type: "canUpdate"
    }
  },

  // Data Rekap
  {
    routeType: "private",
    path: "/rekap",
    element: ListRekap,
    exact: true,
    accessRight: {
      id: "rekap",
      type: "canView"
    }
  },
  {
    routeType: "private",
    path: "/rekap/detail-rekap/:id",
    element: DetailRekap,
    exact: true,
    accessRight: {
      id: "rekap",
      type: "canView"
    }
  },
  {
    routeType: "private",
    path: "/identity",
    element: ListRekap,
    exact: true,
    accessRight: {
      id: "identity",
      type: "canView"
    }
  },
];

export default routesName;
