import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row, Select } from "antd";
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { SubOutputForm } from "./SubOutputForm";
import { MASTER_DATA_URL } from "services/config";
import axios from "axios";
import { IndicatorForm } from "./IndicatorForm";
import { ReactComponent as Confirmation } from "../../../assets/logo/Confirmation.svg"

export const OutputForm = ({ Form, form, disable, name, priorityProgramIndex, outcomeIndex }) => {
   const [listOutput, setListOutput] = useState([]);

   const requiredField = [
      { required: true, message: 'Wajib Diisi' },
      { min: 5, max: 255, message: 'Minimal 5 - 255 karakter' },
   ];

   const loadListOutput = () => {
      axios.get(`${MASTER_DATA_URL}/Output/list`)
         .then(res => {
            let arr = [];

            res.data.data?.forEach(item => {
               arr.push({
                  ...item,
                  key: item.id,
                  value: item.numberingFormat + " " + item.nama,
                  label: item.numberingFormat + " " + item.nama
               })
            })

            setListOutput(arr);
         })
         .catch(err => {
            console.log(err)
         })
   }

   useEffect(() => {
      loadListOutput();
   }, [])

   const handleDeleteOutput = (remove, idx) => {
		Modal.confirm({
			centered: true,
			title: '',
			icon: <Confirmation />,
			okText: "Confirm",
			bodyStyle: { justifyContent: "center" },
			content: (
				<>
					<h1 className="mb-4" style={{ fontSize: "17px", fontWeight: "600", lineHeight: "24px", justifyContent: "center" }}>
						Anda yakin menghapus Output ini?
					</h1>
				</>
			),
			onOk() {
				remove(idx)
			}
		})
	}

   return (
      <Form.List name={[outcomeIndex, "outputs"]}>
         {(outputs, { add, remove }) => (
            <>
               {outputs.map((output, outputIndex) => {
                  return (
                     <div className="mt-4">
                        <Row gutter={12}>
                           <Col span={disable ? 24 : 21}
                              xs={disable ? 24 : 12}
                              sm={disable ? 24 : 12}
                              md={disable ? 24 : 16}
                              lg={disable ? 24 : 18}
                              xl={disable ? 24 : 20}
                           >
                              <div className="">
                                 <label className="field-required font-medium">Output</label>&nbsp;
                                    {(outputIndex > 0) && !disable &&
                                       <span><DeleteOutlined onClick={() => { handleDeleteOutput(remove, outputIndex) }} /></span>
                                    }
                                 <Form.Item
                                    name={[outputIndex, 'outputName']}
                                    rules={requiredField}
                                 >
                                    <Select
                                       placeholder="Output"
                                       type="text"
                                       className="mt-2"
                                       disabled={disable}
                                       options={listOutput}
                                       onChange={(value, option) => {
                                          let obj = form.getFieldsValue([name]);
                                          let priorityPrograms = obj[name];

                                          let output;
                                          if (name === "priorityPrograms") {
                                             output = priorityPrograms[priorityProgramIndex].impact.outcomes[outcomeIndex].outputs[outputIndex];
                                          } else {
                                             output = priorityPrograms[priorityProgramIndex].outcomes[outcomeIndex].outputs[outputIndex];
                                          }

                                          output = {
                                             ...output,
                                             originalId: option.id,
                                             outputName: value
                                          }

                                          if (name === "priorityPrograms") {
                                             form.setFieldValue([name, priorityProgramIndex, "impact", "outcomes", outcomeIndex, "outputs", outputIndex], output)
                                          } else {
                                             form.setFieldValue([name, priorityProgramIndex, "outcomes", outcomeIndex, "outputs", outputIndex], output)
                                          }
                                       }}
                                    />
                                 </Form.Item>
                              </div>
                           </Col>

                           {!disable &&
                              <Col span={3} xs={12} sm={12} md={8} lg={6} xl={4}>
                                 {(name === "priorityPrograms") ?
                                    <Button
                                       icon={<PlusOutlined />}
                                       className="btn-primary mt-8 w-full"
                                       disabled={disable}
                                       onClick={() => {
                                          let obj = form.getFieldsValue([name]);
                                          let priorityPrograms = obj[name];

                                          let outputs;
                                          if (name === "priorityPrograms") {
                                             outputs = priorityPrograms[priorityProgramIndex].impact.outcomes[outcomeIndex].outputs;
                                          } else {
                                             outputs = priorityPrograms[priorityProgramIndex].outcomes[outcomeIndex].outputs;
                                          }

                                          if (outputs[outputIndex]) {
                                             if (outputs[outputIndex].subOutputs) {
                                                outputs[outputIndex].subOutputs.push({
                                                   subOutputName: null,
                                                })
                                             } else {
                                                outputs[outputIndex] = {
                                                   ...outputs[outputIndex],
                                                   subOutputs: [{
                                                      subOutputName: null
                                                   }]
                                                }
                                             }
                                          } else {
                                             outputs[outputIndex] = { ...outputs[outputIndex], outputName: null, subOutputs: [] }
                                          }

                                          if (name === "priorityPrograms") {
                                             form.setFieldValue([name, priorityProgramIndex, "impact", "outcomes", outcomeIndex, "outputs"], outputs)
                                          } else {
                                             form.setFieldValue([name, priorityProgramIndex, "outcomes", outcomeIndex, "outputs"], outputs)
                                          }
                                       }}
                                    >
                                       Sub Output
                                    </Button>
                                    :
                                    <Button
                                       icon={<PlusOutlined />}
                                       className="btn-primary mt-8 w-full"
                                       disabled={disable}
                                       onClick={() => {
                                          let obj = form.getFieldsValue([name]);
                                          let priorityPrograms = obj[name];

                                          let outputs;
                                          outputs = priorityPrograms[priorityProgramIndex].outcomes[outcomeIndex].outputs;

                                          if (outputs[outputIndex]) {
                                             if (outputs[outputIndex].indicators) {
                                                outputs[outputIndex].indicators.push({
                                                   indicatorName: null,
                                                })
                                             } else {
                                                outputs[outputIndex] = {
                                                   ...outputs[outputIndex],
                                                   indicators: [{
                                                      indicatorName: null
                                                   }]
                                                }
                                             }
                                          } else {
                                             outputs[outputIndex] = { ...outputs[outputIndex], outputName: null, indicators: [] }
                                          }

                                          form.setFieldValue([name, priorityProgramIndex, "outcomes", outcomeIndex, "outputs"], outputs)

                                       }}
                                    >
                                       Indikator
                                    </Button>
                                 }

                              </Col>
                           }
                        </Row>
                        {(name === "priorityPrograms") ?
                           <SubOutputForm
                              Form={Form}
                              form={form}
                              disable={disable}
                              name={name}
                              priorityProgramIndex={priorityProgramIndex}
                              outcomeIndex={outcomeIndex}
                              outputIndex={outputIndex}
                           />
                           :
                           <IndicatorForm
                              Form={Form}
                              form={form}
                              disable={disable}
                              name={name}
                              priorityProgramIndex={priorityProgramIndex}
                              outcomeIndex={outcomeIndex}
                              outputIndex={outputIndex}
                           />
                        }
                     </div>
                  )
               })}
            </>
         )}
      </Form.List>
   )
}