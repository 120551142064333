import React, { useState, useEffect } from 'react';
import {
  Modal,
  notification,
} from 'antd';
import { BASE_URL } from 'services/config';
import axios from 'axios';
import moment from "moment";
import { Markup } from "interweave";

export default function LogActivityModal(props) {
  const [logActivity, setLogActivity] = useState([]);

  useEffect(() => {
    if (props.openModal) {
      loadLogActivity()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal])

  const loadLogActivity = () => {
    axios.get(`${BASE_URL}/proposal/${props.idProposal}/log-activity/${props.section}`)
      .then(res => {
        let logs = res.data.result;
        let arr = [];
        logs.forEach((log, idx) => {
          arr.push({
            ...log,
            listNotes: log.notes?.split("\n")
          }
          )
        })

        console.log(arr)
        setLogActivity(arr)
      })
      .catch((err) => {
        notification.error({
          message: "Kesalahan",
          description: err.message
        });
      })
  };

  const handleRole = (user) => {
    if (user?.directorate === "presidentDirector") {
      return "Direktur Utama"
    } else if (user?.directorate === "ppd") {
      if (user?.position === "director") {
        return "Direktur PPD"
      } else {
        return "Staff PPD"
      }
    } else if (user?.directorate === "kl") {
      if (user?.position === "KL") {
        return "Direktur KL"
      } else {
        return "Staff KL"
      }
    } else if (user?.directorate === "otherDirectorate") {
      return "Direktorat Lain"
    } else if (user?.directorate === "donor") {
      return "Donor"
    } else {
      return "-"
    }
  }

  return (
    <>
      <Modal
        title={<div style={{ borderBottom: "solid 1px #BDBDBD", paddingBottom: 5, fontWeight: "bold", fontSize: 20 }}>
          Log Aktivitas
        </div>}
        onCancel={() => props.setOpenModal(false)}
        centered
        open={props.openModal}
        width={640}
        style={{ bottom: 50 }}
        footer={null}
      >
        {logActivity && logActivity.map(log => (
          <div style={{ borderBottom: "solid 1px #BDBDBD", marginBottom: 5, paddingBottom: 5 }}>
            <span>{log.createdAt ? moment(log.createdAt).format("DD MMMM YYYY HH:mm") : "-"}</span>
            <div style={{ fontWeight: "bold", fontSize: 20 }}>
              {log?.user?.name || "-"} - {handleRole(log?.user)}
            </div>

            {log.listNotes ? log.listNotes?.map(note => (
              <div style={{ fontSize: 14 }}>
                <Markup content={note}/>
              </div>
            ))
              :
              <div style={{ fontSize: 14 }}>
                Tidak ada log aktivitas
              </div>
            }

          </div>
        ))}
      </Modal>
    </>
  )
}