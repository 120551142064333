export const HomeLined = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export const CalenderIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.166 2.50016H17.4993C17.7204 2.50016 17.9323 2.58796 18.0886 2.74424C18.2449 2.90052 18.3327 3.11248 18.3327 3.3335V16.6668C18.3327 16.8878 18.2449 17.0998 18.0886 17.2561C17.9323 17.4124 17.7204 17.5002 17.4993 17.5002H2.49935C2.27834 17.5002 2.06637 17.4124 1.91009 17.2561C1.75381 17.0998 1.66602 16.8878 1.66602 16.6668V3.3335C1.66602 3.11248 1.75381 2.90052 1.91009 2.74424C2.06637 2.58796 2.27834 2.50016 2.49935 2.50016H5.83268V0.833496H7.49935V2.50016H12.4993V0.833496H14.166V2.50016ZM12.4993 4.16683H7.49935V5.8335H5.83268V4.16683H3.33268V7.50016H16.666V4.16683H14.166V5.8335H12.4993V4.16683ZM16.666 9.16683H3.33268V15.8335H16.666V9.16683Z" fill="#667085" />
    </svg>
);

export const DeleteIcon = ({ stroke }) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.3333 11.0003V10.3337C19.3333 9.40024 19.3333 8.93353 19.1517 8.57701C18.9919 8.2634 18.7369 8.00844 18.4233 7.84865C18.0668 7.66699 17.6001 7.66699 16.6667 7.66699H15.3333C14.3999 7.66699 13.9332 7.66699 13.5767 7.84865C13.2631 8.00844 13.0081 8.2634 12.8483 8.57701C12.6667 8.93353 12.6667 9.40024 12.6667 10.3337V11.0003M14.3333 15.5837V19.7503M17.6667 15.5837V19.7503M8.5 11.0003H23.5M21.8333 11.0003V20.3337C21.8333 21.7338 21.8333 22.4339 21.5608 22.9686C21.3212 23.439 20.9387 23.8215 20.4683 24.0612C19.9335 24.3337 19.2335 24.3337 17.8333 24.3337H14.1667C12.7665 24.3337 12.0665 24.3337 11.5317 24.0612C11.0613 23.8215 10.6788 23.439 10.4392 22.9686C10.1667 22.4339 10.1667 21.7338 10.1667 20.3337V11.0003" stroke={stroke || "#667085"} stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export const GreyDotIcon = () => (
    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="3" cy="3" r="3" fill="#667085" />
    </svg>
);

export const OrangeDotIcon = () => (
    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="3" cy="3" r="3" fill="#F79009" />
    </svg>
);

export const GreenDotIcon = () => (
    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="3" cy="3" r="3" fill="#27AE5F" />
    </svg>
);

export const RedDotIcon = () => (
    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="3" cy="3" r="3" fill="#F04438" />
    </svg>
);

export const LeftArrow = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "5px", marginBottom: "5px" }}>
        <path d="M15.8337 9.99984H4.16699M4.16699 9.99984L10.0003 15.8332M4.16699 9.99984L10.0003 4.1665" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export const RightArrow = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: "5px", marginBottom: "5px" }}>
        <path d="M4.16699 9.99984H15.8337M15.8337 9.99984L10.0003 4.1665M15.8337 9.99984L10.0003 15.8332" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export const SaveIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 13.8333H13.8333V3.69L12.31 2.16667H11.3333V5.5H3.83333V2.16667H2.16667V13.8333H3V8H13V13.8333ZM1.33333 0.5H13L15.2558 2.75583C15.4121 2.91208 15.5 3.124 15.5 3.345V14.6667C15.5 14.8877 15.4122 15.0996 15.2559 15.2559C15.0996 15.4122 14.8877 15.5 14.6667 15.5H1.33333C1.11232 15.5 0.900358 15.4122 0.744078 15.2559C0.587797 15.0996 0.5 14.8877 0.5 14.6667V1.33333C0.5 1.11232 0.587797 0.900358 0.744078 0.744078C0.900358 0.587797 1.11232 0.5 1.33333 0.5V0.5ZM4.66667 9.66667V13.8333H11.3333V9.66667H4.66667Z" fill="#344054" />
    </svg>
);

export const DownloadAbleIcon = () => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="2" width="32" height="32" rx="16" fill="#D6FFE8" />
        <path d="M19.3327 11.5126V14.2663C19.3327 14.6397 19.3327 14.8264 19.4053 14.969C19.4693 15.0944 19.5712 15.1964 19.6967 15.2603C19.8393 15.333 20.026 15.333 20.3993 15.333H23.153M23.3327 16.6585V21.4663C23.3327 22.5864 23.3327 23.1465 23.1147 23.5743C22.9229 23.9506 22.617 24.2566 22.2407 24.4484C21.8128 24.6663 21.2528 24.6663 20.1327 24.6663H15.866C14.7459 24.6663 14.1859 24.6663 13.758 24.4484C13.3817 24.2566 13.0757 23.9506 12.884 23.5743C12.666 23.1465 12.666 22.5864 12.666 21.4663V14.533C12.666 13.4129 12.666 12.8529 12.884 12.425C13.0757 12.0487 13.3817 11.7427 13.758 11.551C14.1859 11.333 14.7459 11.333 15.866 11.333H18.0072C18.4964 11.333 18.741 11.333 18.9711 11.3883C19.1752 11.4373 19.3703 11.5181 19.5493 11.6277C19.7511 11.7514 19.924 11.9244 20.2699 12.2703L22.3954 14.3957C22.7413 14.7417 22.9143 14.9146 23.038 15.1164C23.1476 15.2954 23.2284 15.4905 23.2774 15.6945C23.3327 15.9247 23.3327 16.1693 23.3327 16.6585Z" stroke="#00BD52" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        <rect x="2" y="2" width="32" height="32" rx="16" stroke="#EEFFF4" stroke-width="4" />
    </svg>
);

export const UploadIcon = () => (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="3" width="40" height="40" rx="20" fill="#F2F4F7" />
        <path d="M19.667 26.3333L23.0003 23M23.0003 23L26.3337 26.3333M23.0003 23V30.5M29.667 26.9524C30.6849 26.1117 31.3337 24.8399 31.3337 23.4167C31.3337 20.8854 29.2816 18.8333 26.7503 18.8333C26.5682 18.8333 26.3979 18.7383 26.3054 18.5814C25.2187 16.7374 23.2124 15.5 20.917 15.5C17.4652 15.5 14.667 18.2982 14.667 21.75C14.667 23.4718 15.3632 25.0309 16.4894 26.1613" stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
        <rect x="3" y="3" width="40" height="40" rx="20" stroke="#F9FAFB" stroke-width="6" />
    </svg>
);