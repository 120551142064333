/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from 'react';
import {
	Space,
	Table,
	Button,
	Input,
	Spin,
	Pagination,
	Select,
	Tag,
	DatePicker
} from 'antd';
import { NavLink } from 'react-router-dom';
import Icon, {
	PlusOutlined,
	SearchOutlined,
	EyeOutlined,
	RightOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import "../index.scss";
import { BASE_URL, OIDC } from 'services/config';
import { CalenderIcon, GreenDotIcon, HomeLined, LeftArrow, OrangeDotIcon, RightArrow } from 'components/atoms/SVG';
import store from 'app/store';

const ListRekening = () => {
	let navigate = useNavigate();
	const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

	const [dataProposal, setDataProposal] = useState([]);
	const [loading, setLoading] = useState();
	const [opt, setOpt] = useState(10);
	const [page] = useState(1);
	const [paginator, setPaginator] = useState();
	const [current, setCurrent] = useState(1);
	const [limit, setLimit] = useState(10);

	const [statusList] = useState([
		{ value: 'requested', label: 'Request Rekening' },
		{ value: 'created', label: 'Sudah Dibuat' },
		{ value: 'partial', label: 'Sudah Dikirim (Angsuran)' },
		{ value: 'paid', label: 'Sudah Dikirim (Selesai)' },
	])

	const oidc_user = store.getState().oidc.oidc_user;
	const user = JSON.parse(oidc_user).profile;

	const [listBypass] = useState([
		{ value: "staff-ppd", label: "staff-ppd" },
		{ value: "direktur-ppd", label: "direktur-ppd" },
		{ value: "staff-kl", label: "staff-kl" },
		{ value: "direktur-kl", label: "direktur-kl" },
		{ value: "direktorat-lain", label: "direktorat-lain" },
		{ value: "direktur-utama", label: "direktur-utama" },
	])

	const [selectedRBP, setSelectedRBP] = useState(null)

	// HANDLE ROLE
	const handleRole = (roleByPass) => {
		let _role = user?.Jabatan + "-" + user?.Directorat;

		if (roleByPass) {
			_role = roleByPass
		} else {
			if (user.Name === "Superadmin Internal") {
				const queryParams = new URLSearchParams(window.location.search);
				_role = queryParams.get('role');
			} else {
				if (user?.JabatanCode === "101") {
					_role = "direktur-utama"
				} else if (user?.DirectoratCode === "004") {
					if (user?.JabatanCode === "401") {
						_role = "direktur-ppd";
					} else {
						_role = "staff-ppd";
					}
				} else if (user?.DirectoratCode && user?.DirectoratCode !== "-") {
					_role = "direktorat-lain";
				} else {
					if (user?.JabatanCode.includes("01")) {
						_role = "direktur-kl";
					} else {
						_role = "staff-kl";
					}
				}
			}
		}

		switch (_role) {
			case "staff-ppd":
				return ({ title: "staff", group: "ppd" });
			case "direktur-ppd":
				return ({ title: "director", group: "ppd" });
			case "staff-kl":
				return ({ title: "staff", group: "kl" });
			case "direktur-kl":
				return ({ title: "director", group: "kl" });
			case "direktorat-lain":
				return ({ title: "director", group: "otherDirectorate" });
			case "direktur-utama":
				return ({ title: "director", group: "presidentDirector" });
			default:
				return ({ title: "director", group: "presidentDirector" });
		}
	}

	const [role, setRole] = useState(handleRole());
	const [searchKey, setSearchKey] = useState("");
	const [searchStatus, setSearchStatus] = useState("");
	const [searchStartDate, setSearchStartDate] = useState("");
	const [searchEndDate, setSearchEndDate] = useState("");
	const [sortDir, setSortDir] = useState();
	const [sortBy, setSortBy] = useState();

	const loadDataProposal = () => {
		setLoading(true);

		let path = `${BASE_URL}/bank-account?page=${current}&limit=${limit}&role=${role.title}&group=${role.group}`

		if (searchKey) {
			path = path + `&search=${searchKey}`
		}

		if (searchStatus) {
			path = path + `&status=${searchStatus}`
		}

		if (searchStartDate) {
			path = path + `&startDate=${searchStartDate}`
		}

		if (searchEndDate) {
			path = path + `&endDate=${searchEndDate}`
		}

		if (sortDir) {
			path = path + `&sort=${sortDir.replace("end", "")}`
		}

		if (sortBy) {
			path = path + `&sortBy=${sortBy}`
		}

		axios.get(path)
			.then(res => {
				setDataProposal(res.data.result);
				setLoading(false);
				setPaginator(res.data.paginator);
			})
			.catch(err => {
				setLoading(false)
			})
	};

	useMemo(() => {
		loadDataProposal()
	}, [searchKey, searchStatus, searchStartDate, searchEndDate, sortDir, sortBy, current, limit])

	const onSearch = (e) => {
		if (e.key === 'Enter') {
			setSearchKey(e.target.value);
		}
	};

	const onSearchStatus = (status) => {
		setSearchStatus(status);
	};

	const onSearchStartDate = (date) => {
		if (date) {
			const d = new Date(date).toLocaleDateString('id-ID');
			setSearchStartDate(moment(d, "DD-MM-YYYY").format("YYYY-MM-DD"));
			// setSearchStartDate(date)
		} else {
			setSearchStartDate(undefined)
		}
	};

	const onSearchEndDate = (date) => {
		if (date) {
			const d = new Date(date).toLocaleDateString('id-ID');
			setSearchEndDate(moment(d, "DD-MM-YYYY").format("YYYY-MM-DD"));
			// setSearchEndDate(date)
		} else {
			setSearchEndDate(undefined)
		}
	};

	function onPaginationChange(limit) {
		setOpt(limit);
		setLimit(limit);
		setCurrent(1);
	}

	function onNumberPaginationChange(changePage) {
		setCurrent(changePage);
	}

	function onSortChange(_sortDir, _sortBy) {
		setCurrent(1);
		setSortBy(_sortBy);
		setSortDir(_sortDir);
	}

	function itemRender(current, type, originalElement) {
		if (type === 'prev') {
			return <span className="">
				<Icon component={LeftArrow} />
				Previous
			</span>;
		}
		if (type === 'next') {
			return <span className="">
				Next
				<Icon component={RightArrow} />
			</span>;
		}
		return originalElement;
	}

	function typeList(value) {
		let tags = [];

		switch (value?.status) {
			case 'requested':
				tags.push(
					tags = { colour: "#FFFAEB", text: "Request Rekening", textColor: "#B54708", dotColor: OrangeDotIcon }
				);
				break;
			case 'created':
				tags.push(
					tags = { colour: "#ECFDF3", text: `Sudah Dibuat`, textColor: "#1C7D44", dotColor: GreenDotIcon }
				);
				break;
			case 'partial':
				tags.push(
					tags = { colour: "#ECFDF3", text: "Sudah Dikirim (Angsuran)", textColor: "#1C7D44", dotColor: GreenDotIcon }
				);
				break;
			case 'paid':
				tags.push(
					tags = { colour: "#ECFDF3", text: "Sudah Dikirim (Selesai)", textColor: "#1C7D44", dotColor: GreenDotIcon }
				);
				break;
			default:
		}

		return <div className="flex mb-2">
			<Tag
				color={tags.colour}
				style={{ color: tags.textColor }}
				className="flex pl-2 pr-2 items-center"
				icon={<Icon component={tags.dotColor} className="" />}
			>
				{
					tags.text
				}
			</Tag>
		</div>
	}

	function onClickDetail(rekening) {
		return navigate(`/rekening/detail-rekening/${rekening}`);
	}

	function proposalStatus(value) {
		return <div className="status">
			<Button type="ghost" style={{ border: "none", marginBottom: "3px" }} onClick={() => onClickDetail(value._id)}>{<EyeOutlined style={{ fontSize: "18px" }} />}</Button>
		</div>
	}

	const columns = [
		{
			title: 'No',
			key: 'number',
			width: '68px',
			height: '72px',
			render: (val, text, data) => {
				return <p>{data + 1 + ((current - 1) * limit)}</p>
			}
		},
		{
			title: 'Judul Proposal',
			format: 'dd/mm/yyyy',
			width: '400px',
			sorter: (a, b, sortDir) => { onSortChange(sortDir, "generalInformation.title") },
			render: (value) => <span>{value.proposalTitle}</span>,
		},
		{
			title: 'No Kontrak',
			width: '400px',
			sorter: (a, b, sortDir) => { onSortChange(sortDir, "contract.number") },
			render: (value) => <span>{value?.contractNumber}</span>,
		},
		{
			title: 'Nama Donor',
			width: '400px',
			sorter: (a, b, sortDir) => { onSortChange(sortDir, "donor.donor") },
			render: (value) => <span>{value.donorName}</span>,
		},
		{
			title: 'Tanggal Pengajuan',
			width: '400px',
			sorter: (a, b, sortDir) => { onSortChange(sortDir, "bankAccount.requestDate") },
			render: (value) => <span>{value.requestDate ? moment(value.requestDate).format('DD/MM/YYYY') : "-"}</span>,
		},
		{
			title: 'Mata Uang',
			width: '400px',
			sorter: (a, b, sortDir) => { onSortChange(sortDir, "bankAccount.currency") },
			render: (value) => <span>{value.currency}</span>,
		},
		{
			title: 'Nama Rekening',
			width: '400px',
			sorter: (a, b, sortDir) => { onSortChange(sortDir, "bankAccount.accountName") },
			render: (value) => <span>{value.accountName ? value.accountName : "-"}</span>,
		},
		{
			title: 'No Rekening',
			width: '400px',
			sorter: (a, b, sortDir) => { onSortChange(sortDir, "bankAccount.accountNumber") },
			render: (value) => <span>{value.accountNumber ? value.accountNumber : "-"}</span>,
		},
		{
			title: 'Status',
			width: '600px',
			sorter: (a, b, sortDir) => { onSortChange(sortDir, "bankAccount.status") },
			render: (value) => <>
				<span>{typeList(value)}</span>
			</>
		},
		{
			title: 'Aksi',
			key: 'action',
			width: '300px',
			align: 'center',
			render: (value) => (proposalStatus(value))
		}
	];

	return (
		<>
			<div className="flex items-center py-4 flex-wrap">
				<Space>
					<Icon component={HomeLined} className="mr-2" />
					<RightOutlined
						className="mr-3 text-xs right-cursor"
					/>
				</Space>
				<div>
					<p className="font-medium" style={{ color: "#01A24A" }}>Daftar Rekening</p>
				</div>
			</div>
			<div className="flex items-center justify-between mb-4">
				<h1 className="text-2xl font-medium">Daftar Rekening</h1>
				{user?.Name === "Superadmin.internal" &&
					<Select
						size="small"
						placeholder="Role Bypass (Superadmin)"
						allowClear
						type="text"
						className="mt-2"
						style={{ height: "48px", width: '300px' }}
						options={listBypass}
						onKeyDown={(e) => {
							setRole(handleRole(e));
							setSelectedRBP(e)
						}}
						onChange={(e) => {
							setRole(handleRole(e));
							setSelectedRBP(e)
						}}
					/>
				}
				{
					(`${role?.title}-${role?.group}` === 'staff-ppd' || `${role?.title}-${role?.group}` === 'direktur-ppd') ?
						<Space>
							<NavLink to={`/rekening/create-rekening/new`}>
								<Button
									htmlType="submit"
									icon={<PlusOutlined />}
									className="btn-primary mt-4"
								>
									<span className="ml-2 text-center">Ajukan Pembuatan Rekening</span>
								</Button>
							</NavLink>
						</Space>
						:
						<></>
				}
			</div>
			<div className="table-list" style={{ border: "1px solid #EAECF0", borderRadius: "12px" }}>
				<div className="flex gap-4 py-6 px-6">
					<Input
						size="large"
						placeholder="Cari"
						className="mt-2 pl-2"
						prefix={<SearchOutlined />}
						onKeyDown={(e) => onSearch(e)}
						style={{ height: "48px" }}
					/>
					<Select
						size="small"
						placeholder="Status"
						allowClear
						type="text"
						className="mt-2"
						style={{ height: "48px", width: '95%' }}
						options={(role.group === "ppd") ? [{ value: 'unavailable', label: 'Belum Dibuat' }, ...statusList] : statusList}
						onKeyDown={(e) => onSearchStatus(e)}
						onChange={(e) => onSearchStatus(e)}
					/>
					<DatePicker
						suffixIcon={<CalenderIcon />}
						placeholder="Start Date"
						className="form-input mt-2"
						prefix={<CalenderIcon />}
						onChange={onSearchStartDate}
						style={{ width: '48%' }}
					>
						<Icon component={CalenderIcon} className="mr-2" style={{ fontSize: 15 }} />
					</DatePicker>
					<DatePicker
						suffixIcon={<CalenderIcon />}
						placeholder="End Date"
						className="form-input mt-2"
						prefix={<CalenderIcon />}
						onChange={onSearchEndDate}
						style={{ width: '48%' }}
					>
						<Icon component={CalenderIcon} className="mr-2" style={{ fontSize: 15 }} />
					</DatePicker>
				</div>
				<div>
					<Spin spinning={loading} indicator={spinIcon}>
						<Table
							columns={columns}
							dataSource={dataProposal}
							pagination={false}
							onChange={onSearch}
							className="px-5"
							style={{ overflowX: "auto" }}
						/>
						<div className="flex table-paginator-wrapper my-3 px-2 justify-between">
							<div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
								<div className="show align-self-center pr-2 font-medium text-sm">
									{'Tampilkan Data'}
								</div>
								<div className="dropdown-paging align-self-center pr-2">
									<Space direction="vertical" style={{ width: '100%' }}>
										<Select
											value={opt}
											className="select-paging-ppd"
											showArrow={true}
											style={{ width: "60px" }}
											options={[
												{ value: 5, label: 5 },
												{ value: 10, label: 10 },
												{ value: 30, label: 30 },
												{ value: 50, label: 50 },
												{ value: 100, label: 100 },
											]}
											onChange={onPaginationChange}
										/>
									</Space>
								</div>
								<div className="border-paging align-self-center pr-2"></div>
								<div className="total align-self-center pr-2">
									{
										`${dataProposal.slice(0, 1).length} - ${dataProposal.length} data dari ${paginator?.itemCount} data`
									}
								</div>
							</div>
							<div>
								<Pagination
									pageSize={opt || 10}
									current={current || page}
									total={paginator?.itemCount}
									onChange={onNumberPaginationChange}
									itemRender={itemRender}
								/>
							</div>
						</div>
					</Spin>
				</div>
			</div>
		</>
	);
}

export default ListRekening;