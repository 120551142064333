/* eslint-disable no-unused-vars */
import React, { useRef, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill-with-table";
import QuillBetterTable from "quill-better-table";
import EditorToolbar, { formats } from "../../molecules/EditorToolbar";

import "react-quill-with-table/dist/quill.snow.css";
import "quill-better-table/dist/quill-better-table.css";

import "./styles.css"

export const CustomQuill = ({ Form, form, disable, name, data, rtfIndex }) => {
  const reactQuillRef = useRef(null); // Ref for ReactQuill component
  // const quillRef = useRef(null); // Ref for Quill instance

  // useEffect(() => {
  //   attachQuillRefs();
  // }, []);

  // const attachQuillRefs = () => {
  //   if (reactQuillRef.current && typeof reactQuillRef.current.getEditor === 'function') {
  //     quillRef.current = reactQuillRef.current.getEditor();
  //   }
  // };

  // const editor = reactQuillRef.getEditor();
  // const unprivilegedEditor = reactQuillRef.makeUnprivilegedEditor(editor);
  // // You may now use the unprivilegedEditor proxy methods
  // unprivilegedEditor.getText();

  const [state, setState] = React.useState({ value: data?.name || form.getFieldValue(name) });
  const handleChange = (value) => {
    console.log(value)
    setState({ value: value })
    form.setFieldValue(name, value);
  };

  React.useEffect(() => {
    if (data) {
      setState({ value: form.getFieldValue(name) })
    } else {
      setState({ value: form.getFieldValue(name) })
    }
    // document.querySelector('.ql-toolbar').addEventListener('mousedown', (e) => {
    //   e.preventDefault();
    // });

    // const editor = reactQuillRef.current?.getEditor();

    // const handleKeyDown = (event) => {
    //   if (event.key === " ") {
    //     const selection = editor.getSelection();
    //     const cursorPosition = selection ? selection.index : 0;
    //     editor.insertText(cursorPosition, " ");
    //     editor.setSelection(cursorPosition + 1);
    //     // event.preventDefault(); // Prevent default behavior of space key
    //   }
    // };

    // editor.root.addEventListener("keydown", handleKeyDown);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, form.getFieldsValue(true)])

  Quill.register({ 'modules/better-table': QuillBetterTable }, true)
  class PreserveWhiteSpace {
    constructor(quill, options) {
      quill.container.style.whiteSpace = "pre-line";
    }
  }
  Quill.register('modules/preserveWhiteSpace', PreserveWhiteSpace);

  const modules =
  {
    preserveWhiteSpace: true,
    toolbar: {
      container: `#toolbar${rtfIndex}`,
      // handlers: {
      //   undo: undoChange,
      //   redo: redoChange,
      //   // insertSymbol: insertSymbol,
      //   // insertTable: handleTableInsert,
      //   // insertRow: addRowBelow,
      //   // insertColumn: addColRight,
      // }
    },
    history: {
      delay: 500,
      maxStack: 100,
      // userOnly: true
    },
    table: false, // disable table module
    "better-table": {
      operationMenu: {
        items: {
          unmergeCells: {
            text: "Another unmerge cells name"
          }
        }
      }
    },
    keyboard: {
      bindings: QuillBetterTable.keyboardBindings
    }
  }
  // // var reactElement = htmlToReactParser.parse(text);

  // useEffect(() => {
  //   const editor = reactQuillRef.current.getEditor();
  //   const toolbar = editor.getModule("toolbar");
  //   toolbar.addHandler("table", () => {
  //     handleTableInsert();
  //   });
  // }, []);

  const handleTableInsert = (e) => {
    const editon = reactQuillRef.current.getEditor();
    console.log(editon)
    let tableModule = editon.getModule("better-table");

    tableModule.insertTable(3, 3);
  };

  const addRowBelow = (e) => {
    // e.stopPropagation();
    const editon = reactQuillRef.current.getEditor();
    let tableModule = editon.getModule("better-table");
    tableModule.insertRowUp();
  };

  const addColRight = () => {
    const editon = reactQuillRef.current.getEditor();
    let tableModule = editon.getModule("better-table");
    tableModule.insertColumnRight();
  };

  const deleteRow = () => {
    const editon = reactQuillRef.current.getEditor();
    let tableModule = editon.getModule("better-table");
    tableModule.deleteRow();
  };

  const deleteColumn = () => {
    const editon = reactQuillRef.current.getEditor();
    let tableModule = editon.getModule("better-table");
    tableModule.deleteColumn();
  };

  const mergeCells = () => {
    const editon = reactQuillRef.current.getEditor();
    let tableModule = editon.getModule("better-table");
    tableModule.mergeCells();
  };

  const unmergeCells = () => {
    const editon = reactQuillRef.current.getEditor();
    let tableModule = editon.getModule("better-table");
    tableModule.unmergeCells();
  };

  function insertSymbol(symbol) {
    const cursorPosition = reactQuillRef?.current?.editor?.getSelection()?.index || 0;
    reactQuillRef.current.editor?.insertText(cursorPosition, symbol);
    reactQuillRef.current.editor?.setSelection(cursorPosition + 1);
  }

  function undoChange() {
    console.log(reactQuillRef.current.editor.history)
    reactQuillRef.current?.editor.history?.undo();
  }
  function redoChange() {
    reactQuillRef.current?.editor.history?.redo();
  }

  // Custom keyboard bindings
  // useEffect(() => {
  //   const editor = reactQuillRef.current?.getEditor();
  //   if (!editor) return;

  //   const handleKeyDown = (event) => {
  //     if (event.key === " ") {
  //       const selection = editor.getSelection();
  //       const cursorPosition = selection ? selection.index : 0;
  //       editor.insertText(cursorPosition, " ");
  //       // editor.setSelection(cursorPosition + 1);
  //       // event.preventDefault(); // Prevent default behavior of space key
  //     }
  //   };

  //   editor.root.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     editor.root.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  return (
    <Form.Item name={name}>
      <div className={`text-editor`} id={name}>
        <EditorToolbar name={name} rtfIndex={rtfIndex} disable={disable} />

        {!disable &&
          <div>
            <button type="button" className="btn-quill" id="insert-table" onClick={handleTableInsert}>
              Insert table
            </button>
            <button type="button" className="btn-quill" onClick={addRowBelow}>
              Add row below
            </button>
            <button type="button" className="btn-quill" onClick={addColRight}>
              Add column right
            </button>
            <button type="button" className="btn-quill" onClick={deleteRow}>
              Delete row
            </button>
            <button type="button" className="btn-quill" onClick={deleteColumn}>
              Delete column
            </button>
            <button type="button" className="btn-quill" onClick={mergeCells}>
              Merge cell
            </button>
            <button type="button" className="btn-quill" onClick={unmergeCells}>
              Split cell
            </button>

            <button type="button" className="btn-quill" style={{ marginLeft: 15 }} onClick={() => insertSymbol("∆")}>
              ∆
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("∑")}>
              ∑
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("∞")}>
              ∞
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("≈")}>
              ≈
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("≡")}>
              ≡
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("±")}>
              ±
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("√")}>
              √
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("Ω")}>
              Ω
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("π")}>
              π
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("α")}>
              α
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("β")}>
              β
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("µ")}>
              µ
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("˃")}>
              ˃
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("˂")}>
              ˂
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("≥")}>
              ≥
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("≤")}>
              ≤
            </button>

            <button type="button" className="btn-quill" onClick={() => insertSymbol("$")}>
              $
            </button>

            <button type="button" className="btn-quill" onClick={() => insertSymbol("¢")}>
              ¢
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("£")}>
              £
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("¥")}>
              ¥
            </button>
          </div>
        }
        <ReactQuill
          id={name}
          ref={reactQuillRef}
          theme="snow"
          readOnly={disable}
          style={{ background: disable ? '#EFF0F1' : '#FFFF', cursor: disable ? 'not-allowed' : '', whiteSpace: "pre-wrap" }}
          modules={modules}
          value={state.value || ""}
          onChange={handleChange}
          preserveWhitespace={true}
        />
      </div>

    </Form.Item>
  );
};

export default CustomQuill;