import React, { useState, useEffect } from 'react';
import { Space, Table, Button, Spin } from 'antd';
import { LoadingOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';

import { BASE_URL } from 'services/config';

const DetailDonor = () => {
  const params = useParams();
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

  const [dataDonor, setDataDonor] = useState();
  const [acreditedEntities, setAcreditedEntities] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadDetailDataDonor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDetailDataDonor = () => {
    axios.get(`${BASE_URL}/recap/${params.id}/donor`).then((res) => {
      setLoading(false);
      setDataDonor(res.data?.result);
      setAcreditedEntities(res.data?.result?.pic);
    });
  };

  const columns = [
    {
      title: 'PIC',
      dataIndex: 'pic',
      key: 'pic',
      width: '33%',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.pic?.localeCompare(b.pic),
    },
    {
      title: 'Alamat',
      dataIndex: 'address',
      key: 'address',
      width: '33%',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.address?.localeCompare(b.address),
    },
    {
      title: 'No Telepone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: '33%',
      render: (text) => <a>{text}</a>,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.phoneNumber?.localeCompare(b.phoneNumber),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '33%',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.email?.localeCompare(b.email),
    },
  ];

  return (
    <>
      <Spin spinning={loading} indicator={spinIcon}>
        <div>
          <div
            className="list overflow-hidden mb-4"
            style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
          >
            <div
              className=""
              style={{ background: '#00BD52', height: '10px' }}
            ></div>
            <div className="px-5 py-5">
              <p className="text-heading">Detail Donor</p>
            </div>
            <hr />
            <div className="flex place-items-center px-5 mt-4 mb-4">
              <div>
                <div className="mb-4">
                  <p className="text-sub-heading">Nama Donor</p>
                  <span className="text-body">{dataDonor?.name || '-'}</span>
                </div>
                <div className="mb-4">
                  <p className="text-sub-heading">Program Tematik</p>
                  {dataDonor?.sectors ? (
                    dataDonor?.sectors.map((item) => (
                      <span className="text-body">
                        {item.name}
                        <br />
                      </span>
                    ))
                  ) : (
                    <span className="text-body">{'-'}</span>
                  )}
                </div>
                <div className="mb-4">
                  <p className="text-sub-heading">Email</p>
                  {dataDonor?.emails ? (
                    dataDonor?.emails.map((item) => (
                      <span className="text-body">
                        {item}
                        <br />
                      </span>
                    ))
                  ) : (
                    <span className="text-body">{'-'}</span>
                  )}
                </div>
                <div className="mb-4">
                  <p className="text-sub-heading">No Telepon</p>
                  {dataDonor?.phoneNumbers ? (
                    dataDonor?.phoneNumbers.map((item) => (
                      <span className="text-body">
                        {item}
                        <br />
                      </span>
                    ))
                  ) : (
                    <span className="text-body">{'-'}</span>
                  )}
                </div>
                <div>
                  <p className="text-sub-heading">Website</p>
                  <span className="text-body">{dataDonor?.website || '-'}</span>
                </div>
              </div>
            </div>
            <hr className="mb-6" />
          </div>

          <div
            className="list overflow-hidden mt-6"
            style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
          >
            <div
              className=""
              style={{ background: '#00BD52', height: '10px' }}
            ></div>
            <div className="px-5 py-5">
              <p className="text-heading mb-2">Accredited Entity</p>
              <span className="text-slate-600">Informasi Organisasi</span>
            </div>
            <hr />
            <div className="flex place-items-center px-5 mt-4 mb-4">
              <div style={{ width: '100%' }}>
                <div>
                  <p className="text-sub-heading mb-2">Nama Organisasi</p>
                  <span className="text-body">
                    {dataDonor?.organizationName || '-'}
                  </span>
                </div>
                <div
                  className="mt-4 mb-4"
                  style={{
                    border: '1px solid #EAECF0',
                    borderRadius: '12px',
                    boxShadow:
                      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                  }}
                >
                  <Spin tip="Loading" spinning={loading}>
                    <Table
                      columns={columns}
                      dataSource={acreditedEntities}
                      pagination={false}
                    />
                  </Spin>
                </div>
              </div>
            </div>
            <hr className="mb-6" />
          </div>

          <div
            className="list mt-6 px-4"
            style={{
              borderRadius: '8px',
              border: '1px solid #EAECF0',
              position: 'fixed',
              bottom: 0,
              width: 'calc(100vw - 400px)',
            }}
          >
            <div className="flex justify-between items-center">
              <Space>
                <NavLink to={'/rekap'}>
                  <Button
                    style={{
                      borderRadius: '8px',
                      border: '1px solid #EAECF0',
                      width: '120px',
                      height: '50px',
                    }}
                    icon={<ArrowLeftOutlined />}
                    className="mt-4 mb-4 flex justify-center items-center font-medium"
                  >
                    <span className="ml-2 text-center">Kembali</span>
                  </Button>
                </NavLink>
              </Space>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default DetailDonor;
