export const proposalStatus = {
  approved: 'Disetujui',
  rejected: 'Ditolak',
  review: 'Sedang Direview',
  draft: 'Baru',
};

export const proposalStatusBgColor = {
  approved: 'rgba(255, 206, 86, 1)',
  rejected: 'rgba(255, 99, 132, 1)',
  review: 'rgba(54, 162, 235, 1)',
  draft: '#01A24A',
};
