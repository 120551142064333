import React, { useMemo } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  defer,
  Navigate,
  useOutletContext,
} from "react-router-dom";
import { map, filter } from "lodash";

import route from "./route";
import store from "app/store";

import My404Component from "pages/My404Component";
import My401Component from "pages/My401Component";

import { AuthLayout, BasicLayout } from "../components";
import { AuthConsumer } from "providers/authProvider";
import { SigninCallback } from "../components/auth/signinCallback";
import { SignoutCallback } from "../components/auth/signoutCallback";
import { SilentRenew } from "../components/auth/silentRenew";
import { Logout } from "../components/auth/logout";

import { USER } from "services";

const ProtectedRoute = ({ children, accessRight }) => {
  const { userAccessRights } = useOutletContext();

  return (
    <AuthConsumer>
      {({ isAuthenticated, signinRedirect }) => {
        if (isAuthenticated()) {
          console.log(userAccessRights)
          const index = userAccessRights?.findIndex(
            (x) => x.key === accessRight?.id
              && (
                (accessRight?.type === "canView" && x.canView === true) ||
                (accessRight?.type === "canCreate" && x.canCreate === true) ||
                (accessRight?.type === "canDelete" && x.canDelete === true) ||
                (accessRight?.type === "canUpdate" && x.canUpdate === true)
              )
          );
          if (index > -1) {
            return (
              <BasicLayout>
                {React.cloneElement(children)}
              </BasicLayout>
            );
          } else return signinRedirect();
        } else {
          return signinRedirect();
        }
      }}
    </AuthConsumer>
  );
};

const Routes = () => {
  const oidc_user = store.getState().oidc.oidc_user;
  const user = JSON.parse(oidc_user);

  const RouteElements = useMemo(() => {
    return createBrowserRouter([
      {
        element: <SigninCallback />,
        path: "/signin-oidc",
      },
      {
        element: <Logout />,
        path: "/logout",
      },
      {
        element: <SignoutCallback />,
        path: "/signout-oidc",
      },
      {
        element: <SilentRenew />,
        path: "/silentrenew",
      },
      {
        element: <AuthLayout />,
        loader: async ({ signal }) => {
          try {
            if (user?.profile?.UserId) {
              const { data: userDetail } = await USER.getUserDetail(
                user?.profile?.UserId,
                signal
              );
              if (userDetail?.userAccess && Array.isArray(userDetail?.userAccess)) {
                const index = userDetail?.userAccess?.findIndex(
                  (x) => x.moduleName?.toLowerCase() === "ppd"
                );
                if (index > -1) {

                  const userAccessRights = userDetail?.userAccess?.[index]?.menuModules;

                  return defer({
                    userAccessRights,
                  });
                }
              }
              return defer({
                userAccessRights: null,
              });
            }
            return defer({
              userAccessRights: null,
            });
          } catch (error) {
            console.log("Error while getting user access rights ", error);
            return defer({
              userAccessRights: null,
            });
          }
        },
        children: map(route, (val, key) => {
          const Component = val.element;

          if (val.routeType === 'public') {
            return {
              key: key,
              path: val.path,
              element: (
                <BasicLayout>
                  <Component />
                </BasicLayout>
              )
            }
          } else {
            return {
              key: key,
              path: val.path,
              element: (
                <ProtectedRoute accessRight={val?.accessRight}>
                  <Component />
                </ProtectedRoute>
              ),
            };
          }
        }),
      },
      {
        element: <My404Component />,
        path: "*",
      },
      { element: <My401Component />, path: "/unautorized" },
    ]);
  }, [user]);
  return <RouterProvider router={RouteElements} />;
};

export default Routes;
