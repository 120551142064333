import React, { useMemo, useState } from "react";
import { Button, Col, Collapse, Input, InputNumber, Row, Select } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { DeleteIcon } from "components/atoms/SVG";
import { OutcomeForm } from "./OutcomeForm";
import { MASTER_DATA_URL } from "services/config";
import axios from "axios";
import { currencyFormatter, currencyFormatterSpecial, currencyParser } from "utils";

export const ProjectPriorityProgram = ({
   Form,
   form,
   disable,
   expandIcon,
   handlePanelChange,
   requiredField,
   name,
}) => {
   const { Panel } = Collapse;
   const [listImpact, setListImpact] = useState([]);
   const [listThematicProgram, setListThematicProgram] = useState([]);

   const loadListImpact = () => {
      axios.get(`${MASTER_DATA_URL}/Impact/list`)
         .then(res => {
            if (res.data) {
               let arr = []
               res.data.data?.forEach(item => {
                  arr.push({
                     ...item,
                     key: item.id,
                     value: item.nama,
                     label: item.nama
                  })
               })

               setListImpact(arr);
            }
         })
         .catch(err => {
            let arr = [{ id: '2495a538-3d79-4d09-8a61-62eea4321883', value: 'Forest, Land-use and Sustainable Ecosystems Management', label: 'Forest, Land-use and Sustainable Ecosystems Management' }];
            setListImpact(arr);

            console.log(err)
         })
   }

   const loadListThematicProgram = () => {
      axios.get(`${MASTER_DATA_URL}/ThematicProgrammes/list`)
         .then(res => {
            if (res.data) {
               let arr = [];

               res.data.data?.forEach(item => {
                  arr.push({
                     ...item,
                     key: item.id,
                     value: item.nama,
                     label: item.nama
                  })
               })

               setListThematicProgram(arr);
            }
         })
         .catch(err => {
            let arr = [{ id: '2495a538-3d79-4d09-8a61-62eea4321883', value: 'Forest, Land-use and Sustainable Ecosystems Management', label: 'Forest, Land-use and Sustainable Ecosystems Management' }];
            setListThematicProgram(arr);
            console.log(err)
         })
   }

   useMemo(() => {
      loadListImpact();
      loadListThematicProgram();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [form])

   const deletePanel = (remove, field) => (
      <Button
         size="large"
         style={{ borderRadius: "8px", width: "50px", height: "48px", background: "#F04438", color: "#FFFF", border: "none" }}
         className="flex px-2 py-2 pl-4 pr-4 justify-center items-center"
         icon={<DeleteIcon stroke={"#FFF"} />}
         onClick={() => {
            remove(field.name);
         }}
      >
      </Button>
   );

   return (
      <div className="">
         <Collapse
            expandIcon={expandIcon}
            defaultActiveKey={['1']}
            expandIconPosition="end"
            onChange={handlePanelChange}
         >
            <Form.Item
               shouldUpdate
            >
               {
                  () => (
                     <>
                        <Form.List name={name}>
                           {(priorityPrograms, { add, remove }) => (
                              <>
                                 <div>
                                    {priorityPrograms.map((priorityProgram, priorityProgramIndex) => {
                                       return (
                                          <div key={priorityProgram.key}>
                                             <Collapse
                                                expandIcon={expandIcon}
                                                defaultActiveKey={['1']}
                                                expandIconPosition="end"
                                                onChange={handlePanelChange}
                                                className="mt-4"
                                             >
                                                <Panel className="custom-panel px-5 pb-4" style={{ color: "black !important" }} header={`Project Prioritas #${priorityProgramIndex + 1}`} key="1"
                                                   extra={(priorityProgramIndex > 0 && !disable) && deletePanel(remove, priorityProgram)}
                                                >

                                                   <div className="mt-4">
                                                      <label className="field-required font-medium">Tujuan Project</label>
                                                      <Form.Item
                                                         name={[priorityProgramIndex, "programGoals"]}
                                                         rules={requiredField}
                                                      >
                                                         <Input
                                                            placeholder="Tujuan Project"
                                                            type="text"
                                                            className="form-input mt-2"
                                                            disabled={disable}
                                                         />
                                                      </Form.Item>
                                                   </div>

                                                   <hr style={{ width: "100%" }} className="break-line mb-4" />

                                                   <div className="">
                                                      <div>
                                                         <OutcomeForm
                                                            Form={Form}
                                                            form={form}
                                                            disable={disable}
                                                            name={name}
                                                            priorityProgramIndex={priorityProgramIndex}
                                                            expandIcon={expandIcon}
                                                            handlePanelChange={handlePanelChange}
                                                         />
                                                      </div>

                                                      <Row gutter={12} className="mt-4 mb-4" style={{ padding: 10, background: "#EEFFF4" }}>
                                                         <Col span={8}>
                                                            <label className="font-medium">Total Kebutuhan Dana (Rp)</label>
                                                            <Form.Item
                                                               name={[priorityProgramIndex, 'totalAmountRp']}
                                                            >
                                                               <InputNumber
                                                                  placeholder="Total Kebutuhan Dana (Rp)"
                                                                  className="form-input mt-2 w-full"
                                                                  style={{ height: "47px", width: "100%", textAlign: "left" }}
                                                                  formatter={currencyFormatter}
                                                                  parser={currencyParser}
                                                                  controls={false}
                                                                  value={form.getFieldValue([name, priorityProgramIndex, "totalAmountRp"]) || 0}
                                                                  disabled
                                                               />
                                                            </Form.Item>
                                                         </Col>
                                                         <Col span={5}>
                                                            <label className="font-medium">Nilai Kurs</label>
                                                            <Form.Item
                                                               name={[priorityProgramIndex, 'exchangeRate']}
                                                            >
                                                               <InputNumber
                                                                  placeholder="Kurs"
                                                                  className="form-input mt-2 w-full"
                                                                  style={{ height: "47px", width: "100%", textAlign: "left" }}
                                                                  formatter={currencyFormatter}
                                                                  parser={currencyParser}
                                                                  controls={false}
                                                                  defaultValue={1}
                                                                  value={form.getFieldValue([name, priorityProgramIndex, "exchangeRate"]) || 1}
                                                                  disabled={disable}
                                                               />
                                                            </Form.Item>
                                                         </Col>
                                                         <Col span={3}>
                                                            <label className="font-medium">Mata Uang</label>
                                                            <Form.Item
                                                               name={[priorityProgramIndex, 'currency']}
                                                            >
                                                               <Select
                                                                  showSearch
                                                                  placeholder="Mata Uang"
                                                                  className="mt-2"
                                                                  type="text"
                                                                  defaultValue={"IDR"}
                                                                  value={form.getFieldValue([name, priorityProgramIndex, "currency"]) || "IDR"}
                                                                  // tagRender={tagRender}
                                                                  style={{ width: "100%" }}
                                                                  options={[
                                                                     { label: "IDR", value: "IDR", kurs: 1 },
                                                                     { label: "EURO", value: "EURO", kurs: 16390 },
                                                                     { label: "POUNDS", value: "POUNDS", kurs: 18848 },
                                                                     { label: "USD", value: "USD", kurs: 15472 },
                                                                     { label: "AUD", value: "AUD", kurs: 9921 },
                                                                  ]}
                                                                  onChange={(val, option) => {
                                                                     let total = form.getFieldValue([name, priorityProgramIndex, "totalAmountRp"])

                                                                     form.setFieldValue([name, priorityProgramIndex, "exchangeRate"], option.kurs)
                                                                     form.setFieldValue([name, priorityProgramIndex, "totalAmount"], total / option.kurs)
                                                                  }}
                                                                  disabled={disable}
                                                               />
                                                            </Form.Item>
                                                         </Col>
                                                         <Col span={8}>
                                                            <label className="font-medium">Total Nilai Kebutuhan Dana ({form.getFieldValue([name, priorityProgramIndex, "currency"])})</label>
                                                            <Form.Item
                                                               name={[priorityProgramIndex, 'totalAmount']}
                                                            >
                                                               <InputNumber
                                                                  placeholder="Total Nilai Kebutuhan Dana"
                                                                  className="form-input mt-2 w-full"
                                                                  style={{ height: "47px", width: "100%", textAlign: "left" }}
                                                                  formatter={currencyFormatterSpecial}
                                                                  parser={currencyParser}
                                                                  controls={false}
                                                                  disabled
                                                               />
                                                            </Form.Item>
                                                         </Col>
                                                      </Row>
                                                   </div>
                                                </Panel>
                                             </Collapse>
                                          </div>
                                       );
                                    })}
                                 </div>
                                 <hr className="mb-6 mt-4" />
                                 {!disable &&
                                    <div className="flex items-center justify-center">
                                       <Button
                                          style={{ width: "98%", height: "52px" }}
                                          className="btn-default mt-4"
                                          disabled={disable}
                                          icon={<PlusOutlined />}
                                          onClick={() => add()}
                                       >
                                          Tambah Project Prioritas
                                       </Button>
                                    </div>
                                 }
                              </>
                           )}
                        </Form.List>
                     </>
                  )}
            </Form.Item>
         </Collapse>
      </div >
   )
}