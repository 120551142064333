// export const currencyFormatter = (value) => {
//     // return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
//     return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
// };

// export const currencyParser = (value) => {
//     return value.replace(/\$\s?|(.*)/g, '');
// };

export const currencyFormatter = (value) => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  // return value?.toLocaleString('id-ID', { currency: 'IDR', style: 'currency'});
};

export const currencyFormatterSpecial = (value) => {
  if(value){
    value = parseFloat(value).toFixed(2);
  }

  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  // return value?.toLocaleString('id-ID', { currency: 'IDR', style: 'currency'});
};

export const currencyParser = (value) => {
  return value.replace(/\.\s?|(,*)/g, '');
};

export const downloadURI = (uri, name) => {
  let link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  // delete link;
}

export const downloadFile = (url, fileName) => {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    })
    .catch(error => console.error('Error downloading file:', error));
}