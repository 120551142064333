import React from "react";
import { Button, Space, Spin, Row, Col, Tabs } from 'antd';
import '../index.scss'
import Icon, { RightOutlined } from '@ant-design/icons';

import { HomeLined } from "components/atoms/SVG";
import DetailDonor from "./DetailDonor";
import DetailProposal from "./DetailProposal";
import DetailKontrak from "./DetailKontrak";
import DetailRekening from "./DetailRekening";

const DetailRekap = () => {

 const items = [
  {
   key: '1',
   label: 'Informasi Donor',
   children: <DetailDonor />,
  },
  {
   key: '2',
   label: 'Detail Proposal',
   children: <DetailProposal />
  },
  {
   key: '3',
   label: 'Dokumen Kontrak',
   children: <DetailKontrak />,
  },
  {
   key: '4',
   label: 'Informasi Dana',
   children: <DetailRekening />,
  },
 ];

 return (
  <>
    <div className="px-4">
     <div className="flex items-center py-5 flex-wrap">
      <Space>
       <Icon component={HomeLined} className="mr-2" />
       <RightOutlined
        className="mr-3 text-xs right-cursor" />
      </Space>
      <div>
       <p className="font-medium mt-2 mr-3">Daftar Rekap</p>
      </div>
      <Space>
       <RightOutlined
        className="mr-3 text-xs right-cursor" />
      </Space>
      <div>
       <Button
        style={{ background: "#EEFFF4", color: "#01A24A", borderRadius: "8px", border: "none" }}
        className="flex justify-center items-center mt-2"
       >
        <span className="font-medium text-center">Detail</span>
       </Button>
      </div>
     </div>

     <Row>
      <Col span={24}>
       <Tabs
        defaultActiveKey="1"
        items={items}
        // onChange={onChange}
        indicatorSize={(origin) => origin - 16}
       />
      </Col>
     </Row>
    </div >
  </>
 );
}

export default DetailRekap;