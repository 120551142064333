import { Pagination, Space, Select } from 'antd';
import { useState } from 'react';

function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <span className="deo-arrow-left-1" />;
  }
  if (type === 'next') {
    return <span className="deo-arrow-right-1" />;
  }
  return originalElement;
}

const BasePagination = (props) => {
  const [totalDataSource, setTotalDataSource] = useState((props || 10));

  const options = props.options || [10, 30, 50, 100];
  const [opt, setOpt] = useState();

  function onChangePageSize(value) {
    console.log('value', value);
    if (props?.onChangePageSize) {
      props?.onChangePageSize(value, 'pageSize')
    }
    setOpt(value);
  }

  return <div className="flex table-paginator-wrapper my-3 px-2 justify-between">
    <div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
      <div className="show align-self-center pr-2 font-medium text-sm">
        {'Tampilkan Data'}
      </div>
      <div className="dropdown-paging align-self-center pr-2">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Select
            onChange={onChangePageSize}
            value={opt || 10}
            className="select-paging-ppd"
            showArrow={false}
            style={{ width: "55px" }}
            options={[
              { value: 5, label: 5 },
              { value: 10, label: 10 },
              { value: 30, label: 30 },
              { value: 50, label: 50 },
              { value: 100, label: 100 },
            ]}
          />
        </Space>
      </div>
      {props.totalSelected !== undefined && (
        <div className="total align-self-center pr-2">
          {
            `Selected: ${props?.totalSelected || 0}`
          }
        </div>
      )}
      <div className="border-paging align-self-center pr-2">
      </div>
      <div className="total align-self-center pr-2">
        {
          `${0}-${0} data dari ${props?.total || 0} data`
        }
      </div>
      {props?.extraComponent && props?.extraComponent()}
    </div>
    <div>

      <Pagination
        {...props.pagination}
        showSizeChanger={false}
        total={props.total || 1}
        itemRender={itemRender}
        className={'pagination-ppd ' + (props.className || '')}
      />
    </div>
  </div>
}

export default BasePagination;