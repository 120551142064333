import React, { useState, useEffect } from 'react';
import {
 Modal,
 notification,
} from 'antd';
import { BASE_URL } from 'services/config';
import axios from 'axios';
import moment from "moment";

export default function HistoryReviewModal(props) {
 const [historyReview, setHistoryReview] = useState([]);

 useEffect(() => {
  if (props.openModal) {
   loadHistoryReview()
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [props.openModal])

 const loadHistoryReview = () => {
  axios.get(`${BASE_URL}/proposal/${props.idProposal}/review-history`)
   .then(res => {
    let logs = res.data.result;
    let arr = [];
    logs.forEach((log, idx) => {
     arr.push({
      ...log,
      listReviews: log.notes ? log.notes?.split("\n") : null
     }
     )
    })

    console.log(arr)
    setHistoryReview(arr)
   })
   .catch((err) => {
    notification.error({
     message: "Kesalahan",
     description: err.message
    });
   })
 };

 const handleRole = (user) => {
  if (user?.directorate === "presidentDirector") {
   return "Direktur Utama"
  } else if (user?.directorate === "ppd") {
   if (user?.position === "director") {
    return "Direktur PPD"
   } else {
    return "Staff PPD"
   }
  } else if (user?.directorate === "kl") {
   if (user?.position === "KL") {
    return "Direktur KL"
   } else {
    return "Staff KL"
   }
  } else if (user?.directorate === "otherDirectorate") {
   return "Direktorat Lain"
  } else {
   return "Donor"
  }
 }

 const handleStatus = (approved) => {
  // switch (value?.status) {
  //   case 'review':
  //     let text = []
  //     value?.review?.groups.forEach(group => {
  //       text.push(<span className="px-4 py-1" style={{ background: "#EEFFF4", color: "#01A24A", borderRadius: "15px" }}>{group}</span>)
  //     });
  //     return <>
  //       {text.map(item => (
  //         item
  //       ))}
  //     </>
  //   case 'draft':
  //     return <span className="px-4 py-1" style={{ background: "#EEFFF4", color: "#01A24A", borderRadius: "15px" }}>Draft</span>
  //   case 'rejected':
  //     return <span className="px-4 py-1" style={{ background: "#FEF3F2", color: "#B42318", borderRadius: "15px" }}>Ditolak</span>
  //   case 'approved':
  //     return <span className="px-4 py-1" style={{ background: "#EEFFF4", color: "#01A24A", borderRadius: "15px" }}>Disetujui</span>
  //   default:
  // }
  if (approved) {
   return <span className="px-4 py-1" style={{ background: "#EEFFF4", color: "#01A24A", borderRadius: "15px" }}>Disetujui</span>
  } else {
   return <span className="px-4 py-1" style={{ background: "#FEF3F2", color: "#B42318", borderRadius: "15px" }}>Ditolak</span>
  }
 }

 return (
  <>
   <Modal
    title={<div style={{ borderBottom: "solid 1px #BDBDBD", paddingBottom: 5, fontWeight: "bold", fontSize: 20 }}>
     Histori Review & Approval
    </div>}
    onCancel={() => props.setOpenModal(false)}
    centered
    open={props.openModal}
    width={640}
    style={{ bottom: 50 }}
    footer={null}
   >
    {historyReview && historyReview.map(log => (
     <div style={{ borderBottom: "solid 1px #BDBDBD", marginBottom: 5, paddingBottom: 5 }}>
      <span>{log.createdAt ? moment(log.createdAt).format("DD MMMM YYYY HH:mm") : "-"}</span>
      &nbsp;•&nbsp;{handleStatus(log.approved)}
      <div style={{ fontWeight: "bold", fontSize: 20 }}>
       {log?.user?.name || "-"} - {log?.user?.position} - {log?.user?.directorate}
      </div>

      {log.listReviews ? log.listReviews?.map(note => (
       <div style={{ fontSize: 14 }}>
        {note}
       </div>
      ))
       :
       <div style={{ fontSize: 14 }}>
        Tidak ada review
       </div>
      }

     </div>
    ))}
   </Modal>
  </>
 )
}