import React, { useEffect, useState } from "react";
import _ from "lodash";
import data from "app/data.json";

const User = () => {
  return (
    <div className="w-full space-y-4">
      <h3 className="font-bold text-2xl">Users</h3>
      <div className="overflow-x-auto border rounded-xl overflow-hidden">
        <table className="table w-full">
          <thead>
            <tr>
              <th>Name</th>
              <th>Job</th>
              <th>Favorite Color</th>
            </tr>
          </thead>
          <tbody>
            {_.map(Array(10), (dummy, key) => (
              <tr key={key}>
                <td>Cy Ganderton</td>
                <td>Quality Control Specialist</td>
                <td>Blue</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between mx-4">
        <label className="text-sm">Menampilkan 1 - 10</label>
      </div>
    </div>
  );
};

export default User;
