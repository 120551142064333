import React, { useState, useEffect } from 'react';
import {
  Space,
  Row,
  Col,
  Button,
  Spin,
  Form,
  Input,
  Upload,
  message,
  Collapse,
  Select,
  notification,
  DatePicker,
  InputNumber,
} from 'antd';
import Icon, {
  RightOutlined,
  LoadingOutlined,
  EyeOutlined,
  ArrowLeftOutlined,
  UpOutlined,
  DownOutlined,
  EditOutlined,
  SaveOutlined,
} from '@ant-design/icons';
// import DatePicker from "react-datepicker";
import axios from 'axios';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { BASE_URL, OIDC } from 'services/config';

import CustomQuill from 'components/organisms/CustomQuill';

import 'react-datepicker/dist/react-datepicker.css';
import { currencyFormatter, currencyParser } from 'utils';
import { PriorityProgram } from 'components/organisms/PriorityProgram';
import {
  CalenderIcon,
  DeleteIcon,
  DownloadAbleIcon,
  UploadIcon,
  HomeLined,
} from 'components/atoms/SVG';

const EditProposalNonSubmission = () => {
  const params = useParams();
  let navigate = useNavigate();
  const { Dragger } = Upload;
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
  const requiredField = [{ required: true, message: 'Wajib Diisi' }];

  const [dataProposal, setDataProposal] = useState();
  const [loading, setLoading] = useState(true);
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [disable, setDisable] = useState();

  const queryParams = new URLSearchParams(window.location.search);
  const role = queryParams.get('role');
  const group = queryParams.get('group');

  let proposal = {};

  const [documents, setDocuments] = useState();
  const props = {
    name: 'file',
    multiple: true,
    action: 'https://bpldh-api-v2.groot.id/api/file-service/v1/files',
    beforeUpload: (file) => {
      const isTypeAllowed =
        file.type === 'application/pdf' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/svg' ||
        file.type === 'image/png';
      if (!isTypeAllowed) {
        // message.error(`${file.name} is not a pdf file`);
        notification.error({
          message: 'Kesalahan',
          description: `Dokumen yang dapat di-upload adalah Dokumen PDF/JPG/JPEG/SVG/PNG`,
        });
      }

      const isSizeAllowed = file.size <= 100 * 1024 * 1024;
      if (!isSizeAllowed) {
        notification.error({
          message: 'Kesalahan',
          description: `Ukuran Dokumen yang di Upload Melebihi 100MB`,
        });
      }

      return (isTypeAllowed && isSizeAllowed) || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        setDocuments(info.fileList);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    loadDetailDataDonor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleRole = (proposal) => {
    let role = `${OIDC()?.profile?.Jabatan || 'staff'}-${
      OIDC()?.profile?.Directorat || 'ppd'
    }`;

    if (role === '---') {
      role = 'staff-ppd';
    }

    switch (role) {
      case 'staff-ppd':
        if (
          (proposal.status === 'review' &&
            proposal.currentReviewer?.group === 'ppd') ||
          proposal.status === 'rejected' ||
          params.addendum
        ) {
          setDisable(false);
        } else {
          setDisable(true);
        }
        break;
      case 'direktur-ppd':
        if (
          proposal.status === 'review' &&
          proposal.currentReviewer?.group === 'ppd'
        ) {
          setDisable(false);
        } else {
          setDisable(true);
        }
        break;
      case 'staff-kl':
        if (
          proposal.status === 'review' &&
          proposal.currentReviewer?.group === 'kl'
        ) {
          if (
            proposal.review?.reviewers[0].finished === false &&
            proposal.review?.reviewers[0].group === 'kl'
          ) {
            setDisable(false);
          } else {
            setDisable(true);
          }
        } else {
          setDisable(true);
        }
        break;
      case 'direktur-kl':
        if (
          proposal.status === 'review' &&
          proposal.currentReviewer?.group === 'kl'
        ) {
          setDisable(false);
        } else {
          setDisable(true);
        }
        break;
      case 'direktur-lain':
        if (
          proposal.status === 'review' &&
          proposal.currentReviewer?.group === 'otherDirectorate'
        ) {
          setDisable(false);
        } else {
          setDisable(true);
        }
        break;
      default:
    }
  };

  const loadDetailDataDonor = () => {
    axios
      .get(
        `${BASE_URL}/proposal-non-submission/${params.id}?role=${role}&group=${group}`
      )
      .then((res) => {
        proposal = res.data.result;
        console.log(proposal);
        setLoading(false);
        form.setFieldsValue({
          generalInformation: proposal.generalInformation,
        });
        form.setFieldsValue({ donor: proposal.donor });
        form.setFieldsValue({ director: proposal.director });
        form.setFieldsValue({ contactPerson: proposal.contactPerson });
        proposal.submissionFunding.date_period = dayjs(
          proposal.submissionFunding.date_period
        );
        proposal.submissionFunding.end_date_period = dayjs(
          proposal.submissionFunding.end_date_period
        );
        form.setFieldsValue({ submissionFunding: proposal.submissionFunding });
        form.setFieldsValue({ distribution: proposal.distribution });

        proposal.priorityPrograms?.forEach((item, idx) => {
          if (
            Object.prototype.toString.call(
              proposal.priorityPrograms[idx].impact
            ) === '[object Array]'
          ) {
            proposal.priorityPrograms[idx].impact =
              proposal.priorityPrograms[idx].impact[0];
          }

          proposal.priorityPrograms[idx].impact?.outcomes?.forEach(
            (outcome) => {
              outcome?.outputs?.forEach((output) => {
                output?.subOutputs?.forEach((subOutput) => {
                  subOutput?.indicators?.forEach((indicator) => {
                    indicator?.parameters?.forEach((parameter) => {
                      let arr = [];

                      parameter?.indicativeActivities?.forEach((activity) => {
                        arr.push(activity.indicativeActivityName);
                      });

                      parameter._indicativeActivities = arr;
                    });
                  });
                });
              });
            }
          );
        });
        form.setFieldsValue({ priorityPrograms: proposal.priorityPrograms });

        proposal.projectPriorityPrograms?.forEach((item, idx) => {
          proposal.projectPriorityPrograms[idx].outcomes?.forEach((outcome) => {
            outcome?.outputs?.forEach((output) => {
              output?.indicators?.forEach((indicator) => {
                indicator?.parameters?.forEach((parameter) => {
                  let arr = [];

                  parameter?.indicativeActivities?.forEach((activity) => {
                    arr.push(activity.indicativeActivityName);
                  });

                  parameter._indicativeActivities = arr;
                });
              });
            });
          });
        });
        form.setFieldsValue({
          projectPriorityPrograms: proposal.projectPriorityPrograms,
        });
        form.setFieldsValue({ klDirectorate: proposal.klDirectorate });
        form.setFieldsValue({
          investmentCriteria: proposal.investmentCriteria,
        });
        form.setFieldsValue({
          financialInstrument: proposal.financialInstrument,
        });
        form.setFieldsValue({
          organizationBackground: proposal.organizationBackground,
        });
        form.setFieldsValue({ needs: proposal.needs });
        form.setFieldsValue({
          organizationalLandscape: proposal.organizationalLandscape,
        });
        form.setFieldsValue({ principleObstacle: proposal.principleObstacle });
        form.setFieldsValue({
          communicatingResult: proposal.communicatingResult,
        });
        form.setFieldsValue({ projectStructure: proposal.projectStructure });
        form.setFieldsValue({ safeguards: proposal.safeguards });
        form.setFieldsValue({ others: proposal.others });

        form.setFieldsValue({ addendum: proposal.addendum });
        form.setFieldsValue({ parentAddendumId: proposal.parentAddendumId });
        form.setFieldsValue({ status: proposal.status });
        form.setFieldsValue({ review: proposal.review });
        form.setFieldsValue({ draft: proposal.draft });

        console.log(form.getFieldsValue(true));
        setDataProposal(form.getFieldsValue(true));
        handleRole(proposal);
      })
      .catch((err) => {
        notification.error({
          message: 'Kesalahan',
          description: err.message,
        });
      });
  };

  const [openModal, setOpenModal] = useState(false);
  const [logSection, setLogSection] = useState('');

  const buttonLogPanel = (section) => (
    <Button
      className="btn-default"
      type="default"
      onClick={() => {
        setOpenModal(true);
        setLogSection(section);
      }}
    >
      Log Aktivitas
    </Button>
  );

  const handlePanelChange = (panelKey) => {
    const expanded = [...expandedPanels];
    const index = expanded.indexOf(panelKey);

    if (index > -1) {
      expanded.splice(index, 1);
    } else {
      expanded.push(panelKey);
    }

    setExpandedPanels(expanded);
  };

  const expandIcon = ({ isActive }) => {
    if (isActive) {
      return <DownOutlined style={{ fontSize: 14 }} />;
    }
    return <UpOutlined style={{ fontSize: 14 }} />;
  };

  function normalizePayload(formValues) {
    let data = JSON.parse(JSON.stringify(formValues));
    console.log('data', data);

    // if (Object.prototype.toString.call(data.priorityPrograms) === '[object Array]') {
    //   data.priorityPrograms = { ...data.priorityPrograms[0] };
    //   if (Object.prototype.toString.call(data.priorityPrograms.impact) === '[object Array]') {
    //     data.priorityPrograms.impact = data.priorityPrograms.impact[0]
    //   }
    // }

    // if (Object.prototype.toString.call(data.projectPriorityPrograms) === '[object Array]') {
    //   data.projectPriorityPrograms = { ...data.projectPriorityPrograms[0] };
    //   if (Object.prototype.toString.call(data.projectPriorityPrograms.impact) === '[object Array]') {
    //     data.projectPriorityPrograms.impact = data.projectPriorityPrograms.impact[0]
    //   }
    // }

    const payload = {
      ...data,
      financialInstrument: data.financialInstrument,
      investmentCriteria: data.investmentCriteria,
      documents: ['http://localhost:3001/proposal-non-submission'],
      donor: data.donor,
      draft: data.draft,
    };

    if (params.addendum) {
      payload.parentAddendumId = params.id;
    }

    return payload;
  }

  function onFinish(values) {
    const payload = normalizePayload(values);
    setLoading(true);

    let submissionPath = params?.addendum
      ? `${BASE_URL}/proposal/addendum`
      : `${BASE_URL}/proposal/${params.id}/update-review`;
    let submissionMethod = params?.addendum ? 'post' : 'put';

    axios({ method: submissionMethod, url: submissionPath, data: payload })
      .catch((err) => {
        let errorMessage;
        let errorMessages = [];

        if (
          err.response.data.errors === undefined ||
          err.response.data.errors.length === 0
        ) {
          errorMessage = err.response.data.message;
        } else {
          <ul style={{ listStyleType: 'disc' }}>
            {err.response.data.errors.forEach((error) => {
              errorMessages.push(<li>&#x2022; {error.message}</li>);
            })}
          </ul>;
        }
        notification.error({
          message: 'Kesalahan',
          duration: 5,
          description: errorMessage ? errorMessage : errorMessages,
        });
        setLoading(false);
      })
      .then((res) => {
        setLoading(false);
        if (res) {
          notification.success({
            message: 'Data berhasil disimpan!',
            duration: 5,
            description: 'Data yang anda masukan sudah berhasil disimpan.',
          });
          return navigate('/proposal-non-submission');
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const onEditProposal = () => {
    setDisable(false);
  };

  const handleStatus = (value) => {
    switch (value?.status) {
      case 'review':
        let text = [];
        value?.review?.groups.forEach((group) => {
          text.push(
            <span
              className="px-4 py-1"
              style={{
                background: '#EEFFF4',
                color: '#01A24A',
                borderRadius: '15px',
              }}
            >
              {group}
            </span>
          );
        });
        return <>{text.map((item) => item)}</>;
      case 'draft':
        return (
          <span
            className="px-4 py-1"
            style={{
              background: '#EEFFF4',
              color: '#01A24A',
              borderRadius: '15px',
            }}
          >
            Draft
          </span>
        );
      case 'rejected':
        return (
          <span
            className="px-4 py-1"
            style={{
              background: '#FEF3F2',
              color: '#B42318',
              borderRadius: '15px',
            }}
          >
            Ditolak
          </span>
        );
      case 'approved':
        return (
          <span
            className="px-4 py-1"
            style={{
              background: '#EEFFF4',
              color: '#01A24A',
              borderRadius: '15px',
            }}
          >
            Disetujui
          </span>
        );
      default:
    }
  };

  const maximumEndDate = () => {
    let sf = form.getFieldValue('submissionFunding');

    if (sf.grant_period && sf.grant_period_type) {
      if (sf.grant_period_type === 'days') {
        let max = dayjs(sf.date_period).add(sf.grant_period, 'day');
        return dayjs(max).format('YYYY-MM-DD');
      } else if (sf.grant_period_type === 'months') {
        let max = dayjs(sf.date_period).add(sf.grant_period, 'month');
        return dayjs(max).format('YYYY-MM-DD');
      } else {
        let max = dayjs(sf.date_period).add(sf.grant_period, 'year');
        return dayjs(max).format('YYYY-MM-DD');
      }
    } else {
      return null;
    }
  };

  return (
    <>
      <Spin spinning={loading} indicator={spinIcon}>
        <Form
          form={form}
          name="form-edit-proposal"
          onFinish={onFinish}
          initialValues={{
            submissionFunding: { grant_period_type: 'months' },
          }}
        >
          <div className="px-4" style={{ position: 'relative' }}>
            <div className="flex items-center py-5 flex-wrap">
              <Space>
                <Icon component={HomeLined} className="mr-2" />
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <p className="font-medium mt-2 mr-3">
                  Kelola Proposal Non Submission
                </p>
              </div>
              <Space>
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <Button
                  style={{
                    background: '#EEFFF4',
                    color: '#01A24A',
                    borderRadius: '8px',
                    border: 'none',
                  }}
                  className="flex justify-center items-center mt-2"
                >
                  <span className="font-medium text-center">
                    {params.addendum
                      ? 'Create Proposal Adendum'
                      : 'Detail Proposal Non Submission'}
                  </span>
                </Button>
              </div>
            </div>
            <div
              className="overflow-hidden mb-4 mt-2"
              style={{
                borderRadius: '8px',
                border: '1px solid #EAECF0',
                background: '#FFFF',
              }}
            >
              <div
                className=""
                style={{ background: '#00BD52', height: '10px' }}
              ></div>
              <div className="px-5 py-5">
                <p className="text-heading">Informasi Donor</p>
              </div>
              <hr />
              <div className="px-5 mt-4 mb-4">
                {dataProposal?.donor && (
                  <Row className="gap-4" gutter={24}>
                    <Col span={4}>
                      <div className="mb-4">
                        <p className="text-sub-heading">Nama Donor</p>
                        <span className="text-body">
                          {dataProposal?.donor?.donor || '-'}
                        </span>
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="mb-4">
                        <p className="text-sub-heading">Program Tematik</p>
                        {dataProposal?.donor?.sectors
                          ? dataProposal?.donor?.sectors.map((sector) => (
                              <ul className="text-body">
                                <span className="text-body">
                                  {sector.name || '-'}
                                </span>
                              </ul>
                            ))
                          : '-'}
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="mb-4">
                        <p className="text-sub-heading">No Telepon</p>
                        {dataProposal?.donor?.phoneNumbers
                          ? dataProposal?.donor?.phoneNumbers.map(
                              (phoneNumber) => (
                                <ul className="text-body">
                                  <span className="text-body">
                                    {phoneNumber || '-'}
                                  </span>
                                </ul>
                              )
                            )
                          : '-'}
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="mb-4">
                        <p className="text-sub-heading">Email</p>
                        <ul className="text-body">
                          {dataProposal?.donor?.email
                            ? dataProposal?.donor?.email.map((email) => (
                                <ul className="text-body">
                                  <span className="text-body">
                                    {email || '-'}
                                  </span>
                                </ul>
                              ))
                            : '-'}
                        </ul>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div>
                        <p className="text-sub-heading">Website</p>
                        <span className="text-body">
                          {dataProposal?.donor?.website || '-'}
                        </span>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
              <hr className="mb-6 mt-4" />
            </div>

            <div
              className="overflow-hidden mb-4 mt-2"
              style={{
                borderRadius: '8px',
                border: '1px solid #EAECF0',
                background: '#FFFF',
              }}
            >
              <div
                className=""
                style={{ background: '#00BD52', height: '10px' }}
              ></div>
              <Row>
                <Col span={12}>
                  <div className="flex gap-4 px-5 py-5">
                    <p className="text-heading">
                      Detail Proposal {dataProposal?.addendum && '(Adendum)'}
                    </p>

                    <div className="mt-1 mb-4">
                      {handleStatus(dataProposal)}
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="flex gap-4 justify-end items-center mr-4 mt-3">
                    {(localStorage.getItem('role') === 'staff-kl' ||
                      localStorage.getItem('role') === 'direktur-lain') &&
                    localStorage.getItem('role') !== 'staff-ppd' &&
                    localStorage.getItem('role') !== 'direktur-ppd' &&
                    localStorage.getItem('role') !== 'direktur-kl' ? (
                      <div>
                        {disable && (
                          <Button
                            className="btn-default"
                            icon={<EditOutlined />}
                            onClick={onEditProposal}
                          >
                            Ubah
                          </Button>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* {(dataProposal?.status === "approved" && !params.addendum) &&
                      <Button className="btn-default" icon={<FileTextOutlined style={{ fontSize: 18 }} />} onClick={handleAdendum}>Ajukan Adendum</Button>
                    } */}
                    <Button className="btn-default">
                      Histori Review & Approval
                    </Button>
                    <Button
                      className="button-style"
                      icon={<EyeOutlined style={{ fontSize: 18 }} />}
                      style={{
                        width: '160px',
                        height: '44px',
                        border: '1px solid #00BD52',
                        background: '#00BD52',
                        borderRadius: '8px',
                        color: '#FFFFFF',
                        fontWeight: 600,
                      }}
                    >
                      Lihat Proposal
                    </Button>
                  </div>
                </Col>
              </Row>
              {dataProposal?.addendum && (
                <Row>
                  <Col span={12}>
                    <div className="flex gap-4 px-5">
                      <div className="mt-1 mb-4">
                        Proposal Sebelumnya: &nbsp;
                        <span
                          className="px-4 py-1"
                          style={{
                            background: '#F2F4F7',
                            color: '#344054',
                            borderRadius: '15px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            navigate(
                              `/proposal-non-submission/view-proposal/${dataProposal?.parentAddendumId}?role=${role}&group=${group}`
                            );
                          }}
                        >
                          {dataProposal?.parentAddendumId}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              <hr />
              <div className="px-5 mt-4 mb-4">
                <Row className="gap-4" gutter={10}>
                  <Col span={8}>
                    <div className="mb-4">
                      <p className="text-sub-heading">
                        Kementerian Lembaga/Negara
                      </p>
                      <ul
                        className="text-body ml-4"
                        style={{ listStyleType: 'disc' }}
                      >
                        <li>{dataProposal?.klDirectorate?.kl[0]}</li>
                      </ul>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="mb-4">
                      <p className="text-sub-heading">Direktorat</p>
                      <li>
                        {dataProposal?.klDirectorate?.directorate[0] || '-'}
                      </li>
                    </div>
                  </Col>
                </Row>
              </div>
              <hr className="mb-6 mt-4" />

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Dokumen Pendukung"
                  key="1"
                  extra={buttonLogPanel('documents')}
                >
                  <div className="px-4">
                    <div className="flex mt-4 mb-4">
                      <div
                        className="flex justify-between mr-4"
                        style={{
                          border: '1px solid #EAECF0',
                          borderRadius: '12px',
                          padding: '16px',
                          width: '335px',
                          height: '96px',
                        }}
                      >
                        <div className="mr-4">
                          <Icon component={DownloadAbleIcon} />
                        </div>
                        <div className="row mr-10">
                          <p
                            style={{
                              color: '#344054',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: '14px',
                              lineHeight: '20px',
                            }}
                          >
                            Dokumen
                          </p>
                          <p>proposal.pdf (100MB)</p>
                          <strong style={{ color: '#00BD52' }}>Unduh</strong>
                        </div>
                        <div className="ml-4">
                          <Icon component={DeleteIcon} />
                        </div>
                      </div>
                      <div
                        className="flex justify-between"
                        style={{
                          border: '1px solid #EAECF0',
                          borderRadius: '12px',
                          padding: '16px',
                          width: '335px',
                          height: '96px',
                        }}
                      >
                        <div className="mr-4">
                          <Icon component={DownloadAbleIcon} />
                        </div>
                        <div className="row mr-10">
                          <p
                            style={{
                              color: '#344054',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: '14px',
                              lineHeight: '20px',
                            }}
                          >
                            Dokumen Lainnya
                          </p>
                          <p>proposal.pdf (100MB)</p>
                          <strong style={{ color: '#00BD52' }}>Unduh</strong>
                        </div>
                        <div className="ml-4">
                          <Icon component={DeleteIcon} />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <p
                        style={{
                          color: '#667085',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: '14px',
                          lineHeight: '28px',
                        }}
                      >
                        Upload Dokumen Pendukung
                      </p>
                      <span style={{ color: '#98A2B3', fontSize: '12px' }}>
                        *Bisa upload lebih dari satu
                      </span>
                    </div>
                    <div className="row mt-4 mb-4">
                      <Form.Item
                        name="documents"
                        initialValue="https://drive.google.com/user/image.png"
                      >
                        <Dragger {...props} disabled={disable}>
                          <p className="ant-upload-drag-icon flex justify-center">
                            <UploadIcon />
                          </p>
                          <p className="ant-upload-text">
                            <strong style={{ color: '#01A24A' }}>
                              Klik untuk upload
                            </strong>{' '}
                            atau drag and drop
                          </p>
                          <p className="ant-upload-hint">
                            SVG, PNG, JPG or GIF (max.100mb)
                          </p>
                        </Dragger>
                      </Form.Item>
                    </div>
                  </div>
                </Panel>
              </Collapse>
              <hr className="mb-6" />

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Informasi General"
                  key="1"
                  extra={buttonLogPanel('generalInformation')}
                >
                  <div className="mt-2">
                    <div>
                      <div className="row px-2 mt-2">
                        <label className="field-required font-medium">
                          Judul Proposal
                        </label>
                        <Form.Item name={['generalInformation', 'title']}>
                          <Input
                            placeholder="Judul Proposal"
                            type="text"
                            className="form-input mt-2"
                            disabled={disable}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row px-2 mt-2">
                      <div className="">
                        <label className="field-required font-medium">
                          Nama Organisasi
                        </label>
                        <Form.Item
                          name={['generalInformation', 'organizationName']}
                        >
                          <Input
                            placeholder="Nama Organisasi"
                            type="text"
                            className="form-input mt-2"
                            disabled={disable}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row px-2 mt-2">
                      <Row gutter={24} className="">
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Negara
                            </label>
                            <Form.Item name={['generalInformation', 'country']}>
                              <Select
                                placeholder="Negara"
                                type="text"
                                className="mt-2"
                                style={{ width: '100%' }}
                                options={[
                                  { value: 'USD', label: 'Indonesia' },
                                  { value: 'RUPIAH', label: 'United State' },
                                  { value: 'EURO', label: 'England' },
                                ]}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Provinsi
                            </label>
                            <Form.Item
                              name={['generalInformation', 'province']}
                            >
                              <Select
                                placeholder="Provinsi"
                                type="text"
                                className="mt-2"
                                style={{ width: '100%' }}
                                options={[
                                  { value: 'USD', label: 'Jawa Barat' },
                                  { value: 'RUPIAH', label: 'Jawa Timur' },
                                  { value: 'EURO', label: 'DKI Jakarta' },
                                ]}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Kota/Kabupaten
                            </label>
                            <Form.Item name={['generalInformation', 'city']}>
                              <Select
                                placeholder="Kota/Kabupaten"
                                type="text"
                                className="mt-2"
                                style={{ width: '100%' }}
                                options={[
                                  { value: 'USD', label: 'Bandung Kulon' },
                                  {
                                    value: 'RUPIAH',
                                    label: 'Cibeunying Kidul',
                                  },
                                  { value: 'EURO', label: 'Lengkong' },
                                ]}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="row px-2 mt-2">
                      <Row gutter={24} className="">
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Kecamatan
                            </label>
                            <Form.Item
                              name={['generalInformation', 'district']}
                            >
                              <Select
                                placeholder="Kecamatan"
                                type="text"
                                className="mt-2"
                                style={{ width: '100%' }}
                                options={[
                                  { value: 'USD', label: 'Lengkong' },
                                  { value: 'RUPIAH', label: 'Pasir Layung' },
                                  { value: 'EURO', label: 'Cimahi Selatan' },
                                ]}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Kelurahan
                            </label>
                            <Form.Item
                              name={['generalInformation', 'subdistrict']}
                            >
                              <Select
                                placeholder="Kelurahan"
                                type="text"
                                className="mt-2"
                                style={{ width: '100%' }}
                                options={[
                                  { value: 'USD', label: 'Pasir Layung' },
                                  { value: 'RUPIAH', label: 'Turangga' },
                                  { value: 'EURO', label: 'Cikutra' },
                                ]}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Kode Pos
                            </label>
                            <Form.Item name={['generalInformation', 'zipcode']}>
                              <Select
                                placeholder="Kode Pos"
                                type="text"
                                className="mt-2"
                                style={{ width: '100%' }}
                                options={[
                                  { value: 'USD', label: '40256' },
                                  { value: 'RUPIAH', label: '14470' },
                                  { value: 'EURO', label: '50092' },
                                ]}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="row px-2 mt-2">
                      <div className="">
                        <label className="field-required font-medium">
                          Alamat Lengkap
                        </label>
                        <Form.Item name={['generalInformation', 'address']}>
                          <Input.TextArea
                            placeholder="Alamat Lengkap"
                            type="text"
                            className="form-input mt-2"
                            disabled={disable}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row px-2 mt-4">
                      <Row gutter={24} className="">
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              No Telepon
                            </label>
                            <Form.Item
                              name={['generalInformation', 'phoneNumber']}
                            >
                              <Input
                                placeholder="No Telepon"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: '100%' }}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Email
                            </label>
                            <Form.Item name={['generalInformation', 'email']}>
                              <Input
                                placeholder="Email"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: '100%' }}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="">
                            <label className="font-medium">Fax</label>
                            <Form.Item name={['generalInformation', 'fax']}>
                              <Input
                                placeholder="Fax"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: '100%' }}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="row px-2 mt-4">
                      <Row gutter={24} className="">
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Website
                            </label>
                            <Form.Item name={['generalInformation', 'website']}>
                              <Input
                                placeholder="Website"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: '100%' }}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="flex justify-center">
                      <hr className="break-line mb-4" />
                    </div>
                    <div className="px-2">
                      <p
                        className="text-xl font-medium"
                        style={{ color: '#01A24A' }}
                      >
                        Informasi Direktur
                      </p>
                      <div className="row mt-4">
                        <Row gutter={24} className="">
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Jabatan
                              </label>
                              <Form.Item name={['director', 'position']}>
                                <Input
                                  placeholder="Jabatan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: '100%' }}
                                  disabled={disable}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Nama Direktur
                              </label>
                              <Form.Item name={['director', 'name']}>
                                <Input
                                  placeholder="Nama Direktur"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: '100%' }}
                                  disabled={disable}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="">
                              <label className="font-medium">Email</label>
                              <Form.Item name={['director', 'email']}>
                                <Input
                                  placeholder="email"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: '100%' }}
                                  disabled
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <hr className="break-line mb-4" />
                    </div>
                    <div className="px-2">
                      <p
                        className="text-xl font-medium"
                        style={{ color: '#01A24A' }}
                      >
                        Informasi Narahubung
                      </p>
                      <div className="row mt-4">
                        <div className="row mt-2">
                          <div className="">
                            <label className="field-required font-medium">
                              Nama Narahubung
                            </label>
                            <Form.Item name={['contactPerson', 'name']}>
                              <Input
                                placeholder="Alamat Lengkap"
                                type="text"
                                className="form-input mt-2"
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <Row gutter={24} className="">
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Email
                              </label>
                              <Form.Item name={['contactPerson', 'email']}>
                                <Input
                                  placeholder="Jabatan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: '100%' }}
                                  disabled={disable}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                No Telepon
                              </label>
                              <Form.Item
                                name={['contactPerson', 'phoneNumber']}
                              >
                                <Input
                                  placeholder="Nama Direktur"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: '100%' }}
                                  disabled={disable}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="">
                              <label className="font-medium">Fax</label>
                              <Form.Item name={['contactPerson', 'fax']}>
                                <Input
                                  placeholder="Fax"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: '100%' }}
                                  disabled={disable}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                        <div className="row mt-2">
                          <div className="">
                            <label className="field-required font-medium">
                              Alamat Lengkap
                            </label>
                            <Form.Item name={['contactPerson', 'address']}>
                              <Input.TextArea
                                placeholder="Alamat Lengkap"
                                type="text"
                                className="form-input mt-2"
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <hr className="break-line mb-4" />
                    </div>
                    <div className="px-2">
                      <p
                        className="text-xl font-medium"
                        style={{ color: '#01A24A' }}
                      >
                        Dana Pengajuan
                      </p>
                      <div className="row mt-4">
                        <Row gutter={24} className="">
                          <Col span={8}>
                            <div className="">
                              <label
                                className="field-required font-medium"
                                style={{ overflowX: 'visible' }}
                              >
                                Total Dana Program
                              </label>
                              <Row gutter={6} className="">
                                <Col span={9}>
                                  <Form.Item
                                    name={['submissionFunding', 'currency']}
                                    rules={requiredField}
                                  >
                                    <Select
                                      placeholder="USD"
                                      type="text"
                                      className="mt-2"
                                      style={{ width: '100%' }}
                                      options={[
                                        { value: 'USD', label: 'USD' },
                                        { value: 'IDR', label: 'IDR' },
                                        { value: 'EURO', label: 'EURO' },
                                      ]}
                                      disabled={disable}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={15}>
                                  <Form.Item
                                    name={[
                                      'submissionFunding',
                                      'funding_total',
                                    ]}
                                    rules={requiredField}
                                  >
                                    <InputNumber
                                      placeholder="10.000"
                                      className="form-input mt-2 w-full"
                                      style={{
                                        height: '47px',
                                        width: '100%',
                                        textAlign: 'right',
                                      }}
                                      formatter={currencyFormatter}
                                      parser={currencyParser}
                                      controls={false}
                                      disabled={disable}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Col>

                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Jangka Waktu Hibah
                              </label>
                              <Row gutter={6} className="">
                                <Col span={9}>
                                  <Form.Item
                                    name={['submissionFunding', 'grant_period']}
                                    rules={requiredField}
                                  >
                                    <Input
                                      type="text"
                                      className="form-input mt-2"
                                      style={{ width: '100%' }}
                                      disabled={disable}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={15}>
                                  <Form.Item
                                    name={[
                                      'submissionFunding',
                                      'grant_period_type',
                                    ]}
                                  >
                                    <Select
                                      placeholder="days/months/years"
                                      className="mt-2"
                                      prefix={<CalenderIcon />}
                                      type="text"
                                      style={{ width: '100%' }}
                                      options={[
                                        { value: 'days', label: 'Days' },
                                        { value: 'months', label: 'Months' },
                                        { value: 'years', label: 'Years' },
                                      ]}
                                      disabled={disable}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Col>

                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Periode Pelaksanaan Program
                              </label>
                              <Row gutter={6}>
                                <Col span={12}>
                                  <Form.Item
                                    name={['submissionFunding', 'date_period']}
                                    rules={requiredField}
                                  >
                                    <DatePicker
                                      suffixIcon={<CalenderIcon />}
                                      // format={"dd/mm/yyyy"}
                                      placeholder="dd/mm/yyyy"
                                      className="form-input mt-2"
                                      prefix={<CalenderIcon />}
                                      style={{ width: '100%' }}
                                      disabled={disable}
                                    >
                                      <Icon
                                        component={CalenderIcon}
                                        className="mr-2"
                                        style={{ fontSize: 15 }}
                                      />
                                    </DatePicker>
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name={[
                                      'submissionFunding',
                                      'end_date_period',
                                    ]}
                                    rules={requiredField}
                                  >
                                    <DatePicker
                                      suffixIcon={<CalenderIcon />}
                                      // format={"dd/mm/yyyy"}
                                      placeholder="dd/mm/yyyy"
                                      className="form-input mt-2"
                                      prefix={<CalenderIcon />}
                                      style={{ width: '100%' }}
                                      disabledDate={(d) =>
                                        !d || d.isAfter(maximumEndDate())
                                      }
                                      disabled={disable}
                                    >
                                      <Icon
                                        component={CalenderIcon}
                                        className="mr-2"
                                        style={{ fontSize: 15 }}
                                      />
                                    </DatePicker>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Panel>
              </Collapse>
              <hr className="mb-6" />

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Program Prioritas"
                  key="1"
                  extra={buttonLogPanel('priorityPrograms')}
                >
                  <div className="">
                    <PriorityProgram
                      Form={Form}
                      form={form}
                      disable={disable}
                      expandIcon={expandIcon}
                      handlePanelChange={handlePanelChange}
                      requiredField={requiredField}
                      name={'priorityPrograms'}
                    />
                  </div>
                </Panel>
              </Collapse>
              <hr className="mb-6" />

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Organization Background (IEF)"
                  key="1"
                  extra={buttonLogPanel('organizationBackground')}
                >
                  <div className="">
                    <div className="mt-2">
                      <CustomQuill
                        Form={Form}
                        form={form}
                        disable={disable}
                        name="organizationBackground"
                        data={dataProposal}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Needs"
                  key="1"
                  extra={buttonLogPanel('needs')}
                >
                  <div className="">
                    <div className="mt-2">
                      <CustomQuill
                        Form={Form}
                        form={form}
                        disable={disable}
                        name="needs"
                        data={dataProposal}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Organizational Landscape"
                  key="1"
                  extra={buttonLogPanel('organizationalLandscape')}
                >
                  <div className="">
                    <div className="mt-2">
                      <CustomQuill
                        Form={Form}
                        form={form}
                        disable={disable}
                        name="organizationalLandscape"
                        data={dataProposal}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>
              <hr className="mb-6" />

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4 bg-white"
                  header="Program Prioritas"
                  key="1"
                  extra={buttonLogPanel('projectPriorityPrograms')}
                >
                  <div className="">
                    <PriorityProgram
                      Form={Form}
                      form={form}
                      disable={disable}
                      expandIcon={expandIcon}
                      handlePanelChange={handlePanelChange}
                      requiredField={requiredField}
                      name={'projectPriorityPrograms'}
                    />
                  </div>
                </Panel>
                <div className="flex justify-center">
                  <hr style={{ width: '97%' }} className="break-line mb-4" />
                </div>
                <div className="px-5">
                  <div className="">
                    <label className="field-required font-medium">
                      Investment Criteria
                    </label>
                    <Form.Item name="investmentCriteria">
                      <Select
                        className="mt-2"
                        type="text"
                        mode="multiple"
                        options={[
                          {
                            value: 'Investment Criteria 1',
                            label: 'Investment Criteria 1',
                          },
                          {
                            value: 'Investment Criteria 2',
                            label: 'Investment Criteria 2',
                          },
                          {
                            value: 'Investment Criteria 3',
                            label: 'Investment Criteria 3',
                          },
                        ]}
                        disabled={disable}
                      />
                    </Form.Item>
                  </div>
                  <div className="">
                    <label className="field-required font-medium">
                      Financial Instrument
                    </label>
                    <Form.Item name="financialInstrument">
                      <Select
                        className="mt-2"
                        type="text"
                        mode="multiple"
                        options={[
                          {
                            value: 'Financial Instrument 1',
                            label: 'Financial Instrument 1',
                          },
                          {
                            value: 'Financial Instrument 2',
                            label: 'Financial Instrument 2',
                          },
                          {
                            value: 'Financial Instrument 3',
                            label: 'Financial Instrument 3',
                          },
                        ]}
                        disabled={disable}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Collapse>
              <hr className="mb-6" />

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Penyaluran"
                  key="1"
                  extra={buttonLogPanel('distribution')}
                >
                  <div>
                    <div className="row px-4 mt-4">
                      <div className="">
                        <label className="font-medium">Mekanisme Alokasi</label>
                        <Form.Item
                          name={['distribution', 'allocationMechanism']}
                        >
                          <Select
                            className="mt-2"
                            type="text"
                            mode="multiple"
                            options={[
                              { value: 'Alokasi 1', label: 'Alokasi 1' },
                              { value: 'Alokasi 2', label: 'Alokasi 2' },
                              { value: 'Alokasi 3', label: 'Alokasi 3' },
                            ]}
                            disabled={disable}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row px-4 mt-4">
                      <div className="">
                        <label className="font-medium">
                          Mekanisme Penyaluran
                        </label>
                        <Form.Item
                          name={['distribution', 'distributionMechanism']}
                        >
                          <Select
                            className="mt-2"
                            type="text"
                            mode="multiple"
                            options={[
                              { value: 'Mekanisme 1', label: 'Mekanisme 1' },
                              { value: 'Mekanisme 2', label: 'Mekanisme 2' },
                              { value: 'Mekanisme 3', label: 'Mekanisme 3' },
                            ]}
                            disabled={disable}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row px-4 mt-4">
                      <div className="">
                        <label className="font-medium">Lokasi (Provinsi)</label>
                        <Form.Item name={['distribution', 'location']}>
                          <Select
                            className="mt-2"
                            type="text"
                            mode="multiple"
                            options={[
                              { value: 'Lokasi 1', label: 'Lokasi 1' },
                              { value: 'Lokasi 2', label: 'Lokasi 2' },
                              { value: 'Lokasi 3', label: 'Lokasi 3' },
                            ]}
                            disabled={disable}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row px-4 mt-4">
                      <div className="">
                        <label className="font-medium">Penerima Manfaat</label>
                        <Form.Item name={['distribution', 'beneficiary']}>
                          <Select
                            className="mt-2"
                            type="text"
                            mode="multiple"
                            options={[
                              { value: 'penerima 1', label: 'penerima 1' },
                              { value: 'penerima 2', label: 'penerima 2' },
                              { value: 'penerima 3', label: 'penerima 3' },
                            ]}
                            disabled={disable}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Panel>
              </Collapse>
              <hr className="mb-6" />

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Risk Management"
                  key="1"
                  extra={buttonLogPanel('principleObstacle')}
                >
                  <div className="">
                    <div className="mt-2">
                      <CustomQuill
                        Form={Form}
                        disable={disable}
                        name="principleObstacle"
                        form={form}
                        data={dataProposal}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Communication Strategy"
                  key="1"
                  extra={buttonLogPanel('communicatingResult')}
                >
                  <div className="">
                    <div className="mt-2">
                      <CustomQuill
                        Form={Form}
                        disable={disable}
                        name="communicatingResult"
                        form={form}
                        data={dataProposal}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Project Management Structure"
                  key="1"
                  extra={buttonLogPanel('projectStructure')}
                >
                  <div className="">
                    <div className="mt-2">
                      <CustomQuill
                        Form={Form}
                        disable={disable}
                        name="projectStructure"
                        form={form}
                        data={dataProposal}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Safeguards"
                  key="1"
                  extra={buttonLogPanel('safeguards')}
                >
                  <div className="">
                    <div className="mt-2">
                      <CustomQuill
                        Form={Form}
                        disable={disable}
                        name="safeguards"
                        form={form}
                        data={dataProposal}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4 mb-8"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Others"
                  key="1"
                  extra={buttonLogPanel('others')}
                >
                  <div className="">
                    <div className="mt-2">
                      <CustomQuill
                        Form={Form}
                        disable={disable}
                        name="others"
                        form={form}
                        data={dataProposal}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>

            <Row>
              <Col span={24}>
                <div
                  className="mt-6 px-4"
                  style={{
                    borderRadius: '8px',
                    border: '1px solid #EAECF0',
                    background: '#FFFF',
                    position: 'fixed',
                    bottom: 0,
                    width: 'calc(100vw - 400px)',
                  }}
                >
                  {localStorage.getItem('role') === 'staff-ppd' &&
                  localStorage.getItem('role') !== 'staff-kl' &&
                  localStorage.getItem('role') !== 'direktur-ppd' &&
                  localStorage.getItem('role') !== 'direktur-kl' &&
                  localStorage.getItem('role') !== 'direktur-lain' ? (
                    <div className="flex justify-between items-center">
                      <Space>
                        <NavLink to={'/proposal-non-submission'}>
                          <Button
                            style={{
                              borderRadius: '8px',
                              border: '1px solid #EAECF0',
                              width: '240px',
                              height: '50px',
                            }}
                            icon={<ArrowLeftOutlined />}
                            className="flex justify-center items-center font-medium"
                          >
                            <span className="ml-2 text-center">Kembali</span>
                          </Button>
                        </NavLink>
                      </Space>
                      <div className="row">
                        <Form.Item>
                          <Space>
                            {params.addendum && (
                              <Button
                                style={{
                                  borderRadius: '8px',
                                  border: '1px solid #D0D5DD',
                                  width: '160px',
                                  height: '52px',
                                }}
                                icon={<SaveOutlined />}
                                className="flex justify-center items-center font-medium mt-4"
                                onClick={() => {
                                  form.setFieldsValue({ draft: true });
                                  onFinish(form.getFieldsValue(true));
                                }}
                              >
                                <span className="ml-2 text-center">
                                  Simpan & Draft
                                </span>
                              </Button>
                            )}
                            <Button
                              style={{
                                background: '#00BD52',
                                color: '#FFFF',
                                borderRadius: '8px',
                                width: '240px',
                                height: '52px',
                              }}
                              // htmlType="submit"
                              onClick={() => {
                                form.setFieldsValue({ draft: false });
                                onFinish(form.getFieldsValue(true));
                              }}
                              className="flex justify-center items-center font-medium mt-4 ml-4"
                              disabled={
                                (dataProposal?.status === 'approved' &&
                                  !params.addendum) ||
                                disable
                              }
                            >
                              <span className="ml-2 text-center">Submit</span>
                            </Button>
                          </Space>
                        </Form.Item>
                      </div>
                    </div>
                  ) : localStorage.getItem('role') === 'staff-kl' ||
                    localStorage.getItem('role') === 'direktur-lain' ? (
                    <div className="flex justify-between items-center">
                      <Space>
                        <NavLink to={'/proposal-non-submission'}>
                          <Button
                            style={{
                              borderRadius: '8px',
                              border: '1px solid #EAECF0',
                              width: '240px',
                              height: '50px',
                            }}
                            icon={<ArrowLeftOutlined />}
                            className="flex justify-center items-center font-medium"
                          >
                            <span className="ml-2 text-center">Kembali</span>
                          </Button>
                        </NavLink>
                      </Space>
                    </div>
                  ) : localStorage.getItem('role') === 'direktur-ppd' ||
                    localStorage.getItem('role') === 'direktur-kl' ? (
                    <div className="flex justify-between items-center">
                      <Space>
                        <NavLink to={'/proposal-non-submission'}>
                          <Button
                            style={{
                              borderRadius: '8px',
                              border: '1px solid #EAECF0',
                              width: '240px',
                              height: '50px',
                            }}
                            icon={<ArrowLeftOutlined />}
                            className="flex justify-center items-center font-medium"
                          >
                            <span className="ml-2 text-center">Kembali</span>
                          </Button>
                        </NavLink>
                      </Space>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </Spin>
    </>
  );
};

export default EditProposalNonSubmission;
