import React from "react";

const Spinner = ({ theme }) => {
  const themeHandler = (val) => {
    switch (val) {
      case "primary":
        return `border-primary`;
      case "secondary":
        return `border-secondary`;
      case "accent":
        return `border-accent`;
      case "info":
        return `border-info`;
      case "success":
        return `border-success`;
      case "warning":
        return `border-warning`;
      case "error":
        return `border-error`;
      case "active":
        return `border-active`;
      case "ghost":
        return `border-ghost`;
      case "link":
        return `border-link`;
      default:
        return "border-white";
    }
  };
  return (
    // <div className="rounded-full animate-spin w-5 h-5 overflow-hidden">
    //   <div className="w-full h-full border-2" />
    // </div>
    <div
      className={`border-y-2 border-l-2 ${themeHandler(
        theme,
      )} rounded-full animate-spin w-5 h-5 overflow-hidden`}
    />
  );
};

export default Spinner;
