import React, { useEffect, useState } from 'react';
import { Form, Button, Space, notification, Spin, Switch, Select } from 'antd';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LoadingOutlined,
  WarningOutlined,
  SaveOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import './index.scss';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Icon, { RightOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { BASE_URL, MASTER_DATA_URL } from 'services/config';
import { ProjectPriorityProgram } from 'components/organisms/ProjectPriorityProgram';
import CustomQuill from 'components/organisms/CustomQuill';

const HomeLine = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
      stroke="#667085"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const CreateProposalSubmissionStepTwo = () => {
  const params = useParams();
  let navigate = useNavigate();
  let proposal;

  const [form] = Form.useForm();
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
  const requiredField = [{ required: true, message: 'Wajib Diisi' }];

  const [loading, setLoading] = useState(false);
  const [colDistribution, setColDistribution] = useState(true);
  const [colRisk, setColRisk] = useState(true);
  const [colResult, setColResult] = useState(true);
  const [colProjectStructure, setColProjectStructure] = useState(true);
  const [colSafeGuards, setColSafeGuards] = useState(true);
  const [colOthers, setColOthers] = useState(true);
  const [expandedPanels, setExpandedPanels] = useState([]);

  const proposalId = params?.id;
  const [listFI, setListFI] = useState([]);
  const [listIC, setListIC] = useState([]);
  const [listMA, setListMA] = useState([]);
  const [listMP, setListMP] = useState([]);
  const [listPM, setListPM] = useState([]);
  const [listProvinsi, setListProvinsi] = useState([]);

  const loadListProvinsi = () => {
    axios
      .get(`${MASTER_DATA_URL}/Provinsi/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.namaProvinsi,
              label: item.namaProvinsi,
            });
          });

          setListProvinsi(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListIC = () => {
    axios
      .get(`${MASTER_DATA_URL}/InvestmentCriteria/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.nama,
              label: item.nama,
            });
          });

          setListIC(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListFI = () => {
    axios
      .get(`${MASTER_DATA_URL}/FinancialInstrument/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.nama,
              label: item.nama,
            });
          });

          setListFI(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListMA = () => {
    axios
      .get(`${MASTER_DATA_URL}/MekanismeAlokasi/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.nama,
              label: item.nama,
            });
          });

          setListMA(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListMP = () => {
    axios
      .get(`${MASTER_DATA_URL}/MekanismePenyaluran/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.nama,
              label: item.nama,
            });
          });

          setListMP(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListPM = () => {
    axios
      .get(`${MASTER_DATA_URL}/PenerimaManfaat/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.nama,
              label: item.nama,
            });
          });

          setListPM(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const element = document.getElementById('top');
    element.scrollIntoView();

    loadListFI();
    loadListIC();
    loadListMA();
    loadListMP();
    loadListPM();
    loadListProvinsi();
    loadDetailDataProposal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDetailDataProposal = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/proposal/${proposalId}`)
      .then((res) => {
        proposal = res.data.result;
        setLoading(false);

        proposal.priorityPrograms?.forEach((item, idx) => {
          if (proposal.priorityPrograms[idx].impact?.indicators?.length < 1) {
            proposal.priorityPrograms[idx].impact.indicators = [{}];
          }

          proposal.priorityPrograms[idx].impact?.outcomes?.forEach(
            (outcome, outcomeIndex) => {
              if (
                proposal.priorityPrograms[idx].impact?.outcomes[outcomeIndex]
                  .indicators?.length < 1 ||
                !proposal.priorityPrograms[idx].impact?.outcomes[outcomeIndex]
                  .indicators
              ) {
                proposal.priorityPrograms[idx].impact.outcomes[
                  outcomeIndex
                ].indicators = [{}];
              }
              outcome?.outputs?.forEach((output) => {
                output?.subOutputs?.forEach((subOutput) => {
                  subOutput?.indicators?.forEach((indicator) => {
                    indicator?.parameters?.forEach((parameter) => {
                      let arr = [];

                      parameter?.indicativeActivities?.forEach((activity) => {
                        arr.push(activity.indicativeActivityName);
                      });

                      parameter._indicativeActivities = arr;
                    });
                  });
                });
              });
            }
          );
        });
        form.setFieldsValue({ priorityPrograms: proposal.priorityPrograms });

        if (
          proposal.projectPriorityPrograms &&
          proposal.projectPriorityPrograms.length > 0
        ) {
          form.setFieldsValue({
            projectPriorityPrograms: proposal.projectPriorityPrograms,
          });
        } else {
          form.setFieldsValue({
            projectPriorityPrograms: [
              {
                outcomes: [{ outputs: [{ budgetAllocation: 0 }] }],
                totalAmount: 0,
                totalAmountRp: 0,
                exchangeRate: 1,
                currency: 'IDR',
              },
            ],
          });
        }
        form.setFieldsValue({
          investmentCriteria: proposal.investmentCriteria,
        });
        form.setFieldsValue({
          financialInstrument: proposal.financialInstrument,
        });

        form.setFieldsValue({ distribution: proposal.distribution });
        form.setFieldsValue({ principleObstacle: proposal.principleObstacle });
        form.setFieldsValue({
          communicatingResult: proposal.communicatingResult,
        });
        form.setFieldsValue({ projectStructure: proposal.projectStructure });
        form.setFieldsValue({ safeguards: proposal.safeguards });
        form.setFieldsValue({ others: proposal.others });
      })
      .catch((err) => {
        let errorMessage =
          err?.response?.data.message || 'Internal Server Error';
        notification.error({
          message: 'Kesalahan',
          duration: 5,
          description: errorMessage,
        });
        setLoading(false);
      });
  };

  const normalizePayload = (formValues) => {
    let data = JSON.parse(JSON.stringify(formValues));
    console.log('data', data);

    // if (Object.prototype.toString.call(data.projectPriorityPrograms) === '[object Array]') {
    //   data.projectPriorityPrograms = { ...data.projectPriorityPrograms[0] };
    //   if (Object.prototype.toString.call(data.projectPriorityPrograms.impact) === '[object Array]') {
    //     data.projectPriorityPrograms.impact = data.projectPriorityPrograms.impact[0]
    //   }
    // }

    if (
      Object.prototype.toString.call(data.financialInstrument) !==
      '[object Array]'
    ) {
      data.financialInstrument = [data.financialInstrument];
    }

    if (
      Object.prototype.toString.call(data.investmentCriteria) !==
      '[object Array]'
    ) {
      data.investmentCriteria = [data.investmentCriteria];
    }

    const payload = {
      ...data,
    };

    return payload;
  };

  const onFinish = (values, nav_btn = 'next') => {
    setLoading(true);
    const payload = normalizePayload(values);

    axios
      .put(`${BASE_URL}/proposal/${proposalId}/step/2`, payload)
      .catch((err) => {
        let errorMessage;
        let errorMessages = [];

        if (
          err?.response?.data.errors === undefined ||
          err?.response?.data.errors.length === 0
        ) {
          errorMessage = err?.response?.data.message;
        } else {
          <ul style={{ listStyleType: 'disc' }}>
            {err.response.data.errors.forEach((error) => {
              errorMessages.push(<li>&#x2022; {error.message}</li>);
            })}
          </ul>;
        }

        setLoading(false);
        if (nav_btn === 'back') {
          return navigate(
            `/proposal-submission/create-proposal/step/1/${proposalId}`
          );
        } else {
          notification.error({
            message: 'Kesalahan',
            duration: 5,
            description: errorMessage ? errorMessage : errorMessages,
          });
        }
      })
      .then((res) => {
        setLoading(false);
        if (res) {
          if (nav_btn === 'back') {
            return navigate(
              `/proposal-submission/create-proposal/step/1/${proposalId}`
            );
          } else if (nav_btn === 'list') {
            return navigate(`/proposal-submission`);
          } else {
            return navigate(
              `/proposal-submission/create-proposal/step/3/${proposalId}`
            );
          }
        }
      });
  };

  const onColDistributionChange = (checked) => {
    setColDistribution(checked);
  };

  const onColRiskChange = (checked) => {
    setColRisk(checked);
  };

  const onColResultChange = (checked) => {
    setColResult(checked);
  };

  const onColProjectStructureChange = (checked) => {
    setColProjectStructure(checked);
  };

  const onColSageGuardsChange = (checked) => {
    setColSafeGuards(checked);
  };

  const onColOthersChange = (checked) => {
    setColOthers(checked);
  };

  const handlePanelChange = (panelKey) => {
    const expanded = [...expandedPanels];
    const index = expanded.indexOf(panelKey);

    if (index > -1) {
      expanded.splice(index, 1);
    } else {
      expanded.push(panelKey);
    }

    setExpandedPanels(expanded);
  };

  const expandIcon = ({ isActive }) => {
    if (isActive) {
      return <DownOutlined style={{ fontSize: 14 }} />;
    }
    return <UpOutlined style={{ fontSize: 14 }} />;
  };

  return (
    <div id="top">
      <Spin spinning={loading} indicator={spinIcon}>
        <div className="px-4">
          <Form
            form={form}
            name="form-create-proposal-step-2"
            onFinish={onFinish}
            initialValues={{
              projectPriorityPrograms: [{}],
            }}
          >
            <div className="flex items-center py-5 flex-wrap">
              <Space>
                <Icon component={HomeLine} className="mr-2" />
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <p className="font-medium mt-2 mr-3">
                  Daftar Proposal Submission
                </p>
              </div>
              <Space>
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <Button
                  style={{
                    background: '#EEFFF4',
                    color: '#01A24A',
                    borderRadius: '8px',
                    border: 'none',
                  }}
                  className="flex justify-center items-center mt-2"
                >
                  <span className="font-medium text-center">
                    Ajukan Proposal
                  </span>
                </Button>
              </div>
            </div>
            <div className="mb-4" style={{ marginBottom: '40px' }}>
              <div div className="flex" style={{ marginLeft: '150px' }}>
                <div style={{ flex: '1' }}>
                  <div
                    className="flex"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      className="circle flex"
                      style={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                        border: '1px solid #EEFFF4',
                        background: '#EEFFF4',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className="inner-circle"
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          background: '#00BD52',
                        }}
                      ></div>
                    </div>
                    <div
                      className="pipe"
                      style={{
                        background: '#00BD52',
                        width: '100%',
                        height: '2px',
                      }}
                    ></div>
                  </div>
                  <div className="tittle">
                    <p
                      style={{
                        color: '#00BD52',
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '-45px',
                        marginTop: '5px',
                      }}
                    >
                      Informasi General
                    </p>
                  </div>
                </div>
                <div style={{ flex: '1' }}>
                  <div
                    className="flex"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      className="circle flex"
                      style={{
                        width: '28px',
                        height: '25px',
                        borderRadius: '50%',
                        border: '1px solid #00BD52',
                        background: '#EEFFF4',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className="inner-circle"
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          background: '#00BD52',
                        }}
                      ></div>
                    </div>
                    <div
                      className="pipe"
                      style={{
                        background: '#EAECF0',
                        width: '100%',
                        height: '2px',
                      }}
                    ></div>
                  </div>
                  <div className="tittle">
                    <p
                      style={{
                        color: '#00BD52',
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '-45px',
                        marginTop: '5px',
                      }}
                    >
                      Informasi Project
                    </p>
                  </div>
                </div>
                <div style={{ flex: '1' }}>
                  <div
                    className="flex"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      className="circle flex"
                      style={{
                        width: '28px',
                        height: '25px',
                        borderRadius: '50%',
                        border: '1px solid #EAECF0',
                        background: '#FFFF',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className="inner-circle"
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          background: '#EAECF0',
                        }}
                      ></div>
                    </div>
                    <div
                      className="pipe"
                      style={{
                        background: '#EAECF0',
                        width: '100%',
                        height: '2px',
                      }}
                    ></div>
                  </div>
                  <div className="tittle">
                    <p
                      style={{
                        color: '#344054',
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '-45px',
                        marginTop: '5px',
                      }}
                    >
                      Direktorat & Donor
                    </p>
                  </div>
                </div>
                <div style={{ flex: '1' }}>
                  <div
                    className="flex"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      className="circle flex"
                      style={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                        border: '1px solid #EAECF0',
                        background: '#FFFF',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className="inner-circle"
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          background: '#EAECF0',
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="tittle">
                    <p
                      style={{
                        color: '#344054',
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '-45px',
                        marginTop: '5px',
                      }}
                    >
                      Dokumen Pendukung
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="warning-box">
              <WarningOutlined style={{ fontSize: 20, marginRight: 10 }} />
              <p>
                Silahkan isi semua data di bawah ini untuk bisa melanjutkan
                pengisian ke tahap selanjutnya
              </p>
            </div>
            <div
              className="w-100 overflow-hidden mb-6"
              style={{
                borderRadius: '8px',
                border: '1px solid #EAECF0',
                background: '#FFFF',
              }}
            >
              <div className="">
                <div
                  className=""
                  style={{ background: '#00BD52', height: '10px' }}
                ></div>
                <div className="px-5 py-5">
                  <p className="text-2xl font-medium">Project Prioritas</p>
                </div>
                <hr />
                <ProjectPriorityProgram
                  Form={Form}
                  form={form}
                  expandIcon={expandIcon}
                  handlePanelChange={handlePanelChange}
                  requiredField={[
                    ...requiredField,
                    { min: 5, max: 255, message: 'Minimal 5 - 255 karakter' },
                  ]}
                  name={'projectPriorityPrograms'}
                />
                <div className="flex justify-center">
                  <hr style={{ width: '97%' }} className="break-line mb-4" />
                </div>
                <div className="px-5">
                  <div className="">
                    <label className="field-required font-medium">
                      Investment Criteria
                    </label>
                    <Form.Item name="investmentCriteria" rules={requiredField}>
                      <Select
                        className="mt-2"
                        placeholder="Investment Criteria"
                        type="text"
                        mode="multiple"
                        options={listIC}
                      />
                    </Form.Item>
                  </div>
                  <div className="">
                    <label className="field-required font-medium">
                      Financial Instrument
                    </label>
                    <Form.Item name="financialInstrument" rules={requiredField}>
                      <Select
                        className="mt-2"
                        placeholder="Financial Instrument"
                        type="text"
                        mode="multiple"
                        options={listFI}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="w-100 list overflow-hidden mb-4"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div
                className=""
                style={{ background: '#00BD52', height: '10px' }}
              ></div>
              <div className="px-5 py-5">
                <p className="text-2xl font-medium">Penyaluran</p>
                <div className="flex mt-2">
                  <span>
                    (Non-Aktifkan jika ingin informasi ini tidak ditampilkan)
                  </span>
                  <Switch
                    defaultChecked
                    onChange={onColDistributionChange}
                    className="ml-2"
                  />
                </div>
              </div>
              <hr />
              {colDistribution ? (
                <div>
                  <div className="row px-5 mt-4">
                    <div className="">
                      <label className="field-required font-medium">
                        Mekanisme Alokasi
                      </label>
                      <Form.Item
                        name={['distribution', 'allocationMechanism']}
                        rules={requiredField}
                      >
                        <Select
                          className="mt-2"
                          placeholder="Mekanisme Alokasi"
                          type="text"
                          mode="multiple"
                          options={listMA}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row px-5 mt-4">
                    <div className="">
                      <label className="field-required font-medium">
                        Mekanisme Penyaluran
                      </label>
                      <Form.Item
                        name={['distribution', 'distributionMechanism']}
                        rules={requiredField}
                      >
                        <Select
                          className="mt-2"
                          placeholder="Mekanisme Penyaluran"
                          type="text"
                          mode="multiple"
                          options={listMP}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row px-5 mt-4">
                    <div className="">
                      <label className="field-required font-medium">
                        Lokasi (Provinsi)
                      </label>
                      <Form.Item
                        name={['distribution', 'location']}
                        rules={requiredField}
                      >
                        <Select
                          className="mt-2"
                          placeholder="Lokasi (Provinsi)"
                          type="text"
                          mode="multiple"
                          options={[
                            { label: 'All Province', value: 'All Province' },
                            ...listProvinsi,
                          ]}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row px-5 mt-4">
                    <div className="">
                      <label className="field-required font-medium">
                        Penerima Manfaat
                      </label>
                      <Form.Item
                        name={['distribution', 'beneficiary']}
                        rules={requiredField}
                      >
                        <Select
                          className="mt-2"
                          placeholder="Penerima Manfaat"
                          type="text"
                          mode="multiple"
                          options={listPM}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className="w-100 list overflow-hidden mb-4"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div
                className=""
                style={{ background: '#00BD52', height: '10px' }}
              ></div>
              <div className="px-5 py-5">
                <p className="text-2xl font-medium">Risk Management</p>
                <div className="flex mt-2">
                  <span>
                    (Non-Aktifkan jika ingin informasi ini tidak ditampilkan)
                  </span>
                  <Switch
                    defaultChecked
                    onChange={onColRiskChange}
                    className="ml-2"
                  />
                </div>
              </div>
              <hr />
              {colRisk ? (
                <div className="mt-2 px-5">
                  <CustomQuill
                    Form={Form}
                    name="principleObstacle"
                    form={form}
                    rtfIndex={1}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className="w-100 list overflow-hidden mb-4"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div
                className=""
                style={{ background: '#00BD52', height: '10px' }}
              ></div>
              <div className="px-5 py-5">
                <p className="text-2xl font-medium">Communication Strategy</p>
                <div className="flex mt-2">
                  <span>
                    (Non-Aktifkan jika ingin informasi ini tidak ditampilkan)
                  </span>
                  <Switch
                    defaultChecked
                    onChange={onColResultChange}
                    className="ml-2"
                  />
                </div>
              </div>
              <hr />
              {colResult ? (
                <div className="mt-2 px-5">
                  <CustomQuill
                    Form={Form}
                    name="communicatingResult"
                    form={form}
                    rtfIndex={2}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className="w-100 list overflow-hidden mb-4"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div
                className=""
                style={{ background: '#00BD52', height: '10px' }}
              ></div>
              <div className="px-5 py-5">
                <p className="text-2xl font-medium">
                  Project Management Structure
                </p>
                <div className="flex mt-2">
                  <span>
                    (Non-Aktifkan jika ingin informasi ini tidak ditampilkan)
                  </span>
                  <Switch
                    defaultChecked
                    onChange={onColProjectStructureChange}
                    className="ml-2"
                  />
                </div>
              </div>
              <hr />
              {colProjectStructure ? (
                <div className="mt-2 px-5">
                  <CustomQuill
                    Form={Form}
                    name="projectStructure"
                    form={form}
                    rtfIndex={3}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className="w-100 list overflow-hidden mb-4"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div
                className=""
                style={{ background: '#00BD52', height: '10px' }}
              ></div>
              <div className="px-5 py-5">
                <p className="text-2xl font-medium">Safeguards</p>
                <div className="flex mt-2">
                  <span>
                    (Non-Aktifkan jika ingin informasi ini tidak ditampilkan)
                  </span>
                  <Switch
                    defaultChecked
                    onChange={onColSageGuardsChange}
                    className="ml-2"
                  />
                </div>
              </div>
              <hr />
              {colSafeGuards ? (
                <div className="mt-2 px-5">
                  <CustomQuill
                    Form={Form}
                    name="safeguards"
                    form={form}
                    rtfIndex={4}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className="w-100 list overflow-hidden mb-24"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div
                className=""
                style={{ background: '#00BD52', height: '10px' }}
              ></div>
              <div className="px-5 py-5">
                <p className="text-2xl font-medium">Others</p>
                <div className="flex mt-2">
                  <span>
                    (Non-Aktifkan jika ingin informasi ini tidak ditampilkan)
                  </span>
                  <Switch
                    defaultChecked
                    onChange={onColOthersChange}
                    className="ml-2"
                  />
                </div>
              </div>
              <hr />
              {colOthers ? (
                <div className="mt-2 px-5">
                  <CustomQuill
                    Form={Form}
                    name="others"
                    form={form}
                    rtfIndex={5}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className="mt-6 px-4"
              style={{
                borderRadius: '8px',
                border: '1px solid #EAECF0',
                background: '#FFFF',
                position: 'fixed',
                bottom: 0,
                width: 'calc(100vw - 400px)',
              }}
            >
              <div className="flex justify-between items-center">
                <Space>
                  {/* <NavLink to={`/proposal-submission/create-proposal/step/1/${params.id}`}> */}
                  <Button
                    style={{
                      borderRadius: '8px',
                      border: '1px solid #EAECF0',
                      width: '120px',
                      height: '50px',
                    }}
                    icon={<ArrowLeftOutlined />}
                    className="flex justify-center items-center font-medium"
                    onClick={() => {
                      onFinish(form.getFieldsValue(), 'back');
                    }}
                  >
                    <span className="ml-2 text-center">Kembali</span>
                  </Button>
                  {/* </NavLink> */}
                </Space>
                <div className="row">
                  <Form.Item>
                    <Space>
                      {/* <NavLink to={'/proposal-submission'}> */}
                      {/* <Button
                        icon={<SaveOutlined />}
                        className="btn-back"
                        onClick={() => {
                          onFinish(form.getFieldsValue(), 'list');
                        }}
                      >
                        <span className="ml-2 text-center">Simpan & Draft</span>
                      </Button> */}
                      {/* </NavLink> */}
                    </Space>
                    <Space>
                      <Button
                        htmlType="submit"
                        className="btn-submit mt-4 ml-4"
                        // onClick={() => {
                        //   onFinish(form.getFieldsValue(), 'next');
                        // }}
                      >
                        <span className="ml-2 text-center">Selanjutnya</span>
                        <Icon
                          component={ArrowRightOutlined}
                          className="mr-2"
                          style={{ fontSize: 15 }}
                        />
                      </Button>
                    </Space>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default CreateProposalSubmissionStepTwo;
