import React from "react";

import { Header, Sidebar } from "components";
import { motion } from "framer-motion";

const BasicLayout = ({ children }) => {
  return (
    <div>
      <div className="flex flex-col h-screen">
        <div className="flex flex-1 overflow-hidden">
          <Sidebar>{children}</Sidebar>
          <div className="flex-1 overflow-y-auto" style={{ backgroundColor: "#F9FAFB" }}>
            <Header />
            <motion.main
              className="flex-1 overflow-y-auto paragraph p-4 mb-4 mt-10"
              key={window.location.pathname}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.05 }}
            >
              {children}
            </motion.main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicLayout;
